import React from "react";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Services from "./services/Services";
import Routes from "./routes/Routes";
import Globals from "./globals/Globals";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Globals>
        <Services>
          <Routes />
        </Services>
      </Globals>
    </BrowserRouter>
  );
}

export default App;
