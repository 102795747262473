import React from "react";
import { useAuthGlobal } from "../globals/AuthGlobal/AuthGlobal";
import { Redirect, Route } from "react-router-dom";

interface IProps {
  component: React.LazyExoticComponent<any>;
  exact: boolean;
  path: string;
}

function PrivateRoute(props: IProps) {
  const authGlobal = useAuthGlobal();

  const { component: Component, exact, path }: IProps = props;

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        authGlobal.isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

export default PrivateRoute;
