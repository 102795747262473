import React from "react";
import { AuthService } from "./AuthService/AuthService";
import { AdminService } from "./AdminService/AdminService";

interface IProps {
  children: React.ReactElement;
}

function Services(props: IProps) {
  return (
    <AuthService>
      <AdminService>{props.children}</AdminService>
    </AuthService>
  );
}

export default Services;
