import React, { createContext, useContext, useState } from "react";

interface ITopbarGlobalContext {
  buttons: React.ReactElement[] | null;
  updateButtons: (buttons: React.ReactElement[] | null) => void;
}

const TopbarGlobalContext = createContext<ITopbarGlobalContext>({} as ITopbarGlobalContext);

export function useTopbarGlobal(): ITopbarGlobalContext {
  return useContext(TopbarGlobalContext);
}

interface IProps {
  children: React.ReactElement | React.ReactElement[];
}

export function TopbarGlobal(props: IProps) {
  const [buttons, setButtons] = useState<React.ReactElement[] | null>(null);

  const value: ITopbarGlobalContext = {
    buttons: buttons,
    updateButtons: setButtons
  };

  return (
    <TopbarGlobalContext.Provider value={value}>{props.children}</TopbarGlobalContext.Provider>
  );
}
