import React, { createContext, useContext, useState } from "react";
import { Helmet } from "react-helmet";

interface ITitleGlobalContext {
  update: (title: string) => void;
  current: string;
}

const TitleGlobalContext = createContext<ITitleGlobalContext>({} as ITitleGlobalContext);

export function useTitleGlobal(): ITitleGlobalContext {
  return useContext(TitleGlobalContext);
}

interface IProps {
  children: React.ReactNode;
}

export function TitleGlobal(props: IProps) {
  const [title, setTitle] = useState<string>("Hangar");

  const value: ITitleGlobalContext = {
    update: setTitle,
    current: title
  };

  return (
    <TitleGlobalContext.Provider value={value}>
      <Helmet>
        <title>{`${title} - Hangar`}</title>
      </Helmet>
      {props.children}
    </TitleGlobalContext.Provider>
  );
}
