import React, { createContext, useContext, useState } from "react";
import { CircularProgress, Dialog, makeStyles } from "@material-ui/core";

interface ILoadingContext {
  open: () => void;
  close: () => void;
}

const LoadingContext = createContext<ILoadingContext>({} as ILoadingContext);

export function useLoadingGlobal(): ILoadingContext {
  return useContext(LoadingContext);
}

const useStyles = makeStyles({
  dialogPaperPadding: {
    padding: "2rem",
    borderRadius: "50%"
  },

  backdrop: {
    backgroundColor: "rgba(0,0,0,0.2)"
  }
});

interface IProps {
  children: React.ReactNode;
}

export function LoadingGlobal(props: IProps) {
  const classes = useStyles();
  const [isLoadingOpen, setIsLoadingOpen] = useState(false);

  function handleOpen(): void {
    setIsLoadingOpen(true);
  }

  function handleClose(): void {
    setIsLoadingOpen(false);
  }

  const value: ILoadingContext = {
    open: handleOpen,
    close: handleClose
  };

  return (
    <LoadingContext.Provider value={value}>
      {props.children}
      <Dialog
        open={isLoadingOpen}
        disableBackdropClick={true}
        BackdropProps={{ invisible: false, className: classes.backdrop }}
        PaperProps={{ elevation: 1, className: classes.dialogPaperPadding }}
      >
        <CircularProgress color="secondary" size={120} thickness={3.6} />
      </Dialog>
    </LoadingContext.Provider>
  );
}
