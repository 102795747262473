import React, { useState } from "react";
import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import TranslateRoundedIcon from "@material-ui/icons/TranslateRounded";
import Brightness2RoundedIcon from "@material-ui/icons/Brightness2Rounded";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import { Link } from "react-router-dom";
import { useThemeGlobal } from "../../../globals/ThemeGlobal/ThemeGlobal";
import WbIncandescentRoundedIcon from "@material-ui/icons/WbIncandescentRounded";
import LanguageDialog from "../../LanguageDialog/LanguageDialog";
import { useAuthGlobal } from "../../../globals/AuthGlobal/AuthGlobal";

interface IProps {
  onSidebarClose: () => void;
}

function SidebarBottom(props: IProps) {
  const [isLanguageDialogOpen, setIsLanguageDialogOpen] = useState<boolean>(false);
  const intl = useIntl();
  const themeGlobal = useThemeGlobal();
  const authGlobal = useAuthGlobal();

  function handleChangeLanguageDialogClose(): void {
    setIsLanguageDialogOpen(false);
    props.onSidebarClose();
  }

  function handleChangeThemeListItemClick(): void {
    themeGlobal.updateCurrentThemeType(themeGlobal.currentThemeType === "light" ? "dark" : "light");
    props.onSidebarClose();
  }

  return (
    <List>
      {/* Dili Değiştir */}
      <ListItem button={true} onClick={() => setIsLanguageDialogOpen(true)}>
        <ListItemIcon>
          <TranslateRoundedIcon />
        </ListItemIcon>
        <ListItemText disableTypography={true}>
          <Typography variant="body2">{intl.formatMessage({ id: "CHANGE_LANGUAGE" })}</Typography>
        </ListItemText>
      </ListItem>
      <LanguageDialog isOpen={isLanguageDialogOpen} onClose={handleChangeLanguageDialogClose} />
      {/* Tema */}
      <ListItem button={true} onClick={handleChangeThemeListItemClick}>
        <ListItemIcon>
          {themeGlobal.currentThemeType === "light" ? (
            <Brightness2RoundedIcon />
          ) : (
            <WbIncandescentRoundedIcon />
          )}
        </ListItemIcon>
        <ListItemText disableTypography={true}>
          <Typography variant="body2">
            {intl.formatMessage({
              id: themeGlobal.currentThemeType === "light" ? "DARK_THEME" : "LIGHT_THEME"
            })}
          </Typography>
        </ListItemText>
      </ListItem>
      {/* Hesabım */}
      <ListItem button={true} component={Link} to="/my-account" onClick={props.onSidebarClose}>
        <ListItemIcon>
          <AccountCircleRoundedIcon />
        </ListItemIcon>
        <ListItemText disableTypography={true}>
          <Typography variant="body2">{intl.formatMessage({ id: "MY_ACCOUNT" })}</Typography>
        </ListItemText>
      </ListItem>
      {/* Çıkış Yap */}
      <ListItem button={true} onClick={() => authGlobal.logout()}>
        <ListItemIcon>
          <ExitToAppRoundedIcon />
        </ListItemIcon>
        <ListItemText disableTypography={true}>
          <Typography variant="body2">{intl.formatMessage({ id: "LOGOUT" })}</Typography>
        </ListItemText>
      </ListItem>
    </List>
  );
}

export default SidebarBottom;
