import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Canceler,
  CancelToken,
  CancelTokenSource
} from "axios";
import { useSnackbarGlobal } from "../../globals/SnackbarGlobal/SnackbarGlobal";
import { useAuthGlobal } from "../../globals/AuthGlobal/AuthGlobal";
import { useAuthService } from "../AuthService/AuthService";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import IApiResponseDto from "./IApiResponseDto";
import { useLocaleGlobal } from "../../globals/LocaleGlobal/LocaleGlobal";

export interface IUseAxiosService {
  send: <T>(httpRequest: AxiosRequestConfig) => Promise<T>;
}

export function useAxiosService(axiosInstance: AxiosInstance): IUseAxiosService {
  const snackbarGlobal = useSnackbarGlobal();
  const authGlobal = useAuthGlobal();
  const localeGlobal = useLocaleGlobal();
  const authService = useAuthService();
  const [token, cancelToken] = useCancelToken();
  const history = useHistory();

  useEffect(() => {
    return function cleanup() {
      cancelToken();
    };
  }, []);

  function send<T>(httpRequest: AxiosRequestConfig): Promise<T> {
    setHeaders();

    return new Promise((resolve, reject) => {
      axiosInstance(httpRequest)
        .then((response: AxiosResponse<IApiResponseDto<T>>) =>{ resolve(response.data.Data)})
        .catch(async (error: AxiosError<IApiResponseDto<T>>) => {
          let errorMessage;
          // Timeout & Cancellation errors
          if (!error.response) errorMessage = "İstek zaman aşımına uğradı veya iptal edildi.";
          // Authorization errors
          else if (error.response.status === 401) {
            const refreshToken = authGlobal.getRefreshToken();
            const companyId = authGlobal.getCompanyId();

            if (!refreshToken || !companyId) {
              history.push("/login");

              errorMessage = "Oturum zaman aşımına uğradı. Lütfen tekrar giriş yapınız.";
            } else {
              try {
                const getAccessTokenResponse = await authService.getAccessToken(token, {
                  RefreshToken: refreshToken,
                  CompanyId: companyId
                });

                authGlobal.login(
                  getAccessTokenResponse.RefreshToken,
                  getAccessTokenResponse.AccessToken,
                  companyId
                );

                axiosInstance.defaults.headers["common"][
                  "Authorization"
                ] = `Bearer ${getAccessTokenResponse.AccessToken}`;

                error.config.headers[
                  "Authorization"
                ] = `Bearer ${getAccessTokenResponse.AccessToken}`;

                // Send original request one more time!
                return resolve(send(error.config));
              } catch (error) {
                errorMessage = "Oturum zaman aşımına uğradı. Lütfen tekrar giriş yapınız.";

                authGlobal.logout();

                history.push("/login");
              }
            }
          }
          // Authorization errors
          else if (error.response.status === 403) {
            errorMessage = "Bu işlem için yetkiniz bulunmamaktadır.";
          }
          // Service handled errors
          else errorMessage = error.response.data.Errors[0].Message;

          // We don't show messages which are thrown by cancellation token.
          if (!axios.isCancel(error)) snackbarGlobal.open(errorMessage, "error");

          return reject(errorMessage);
        });
    });
  }

  function setHeaders(): void {
    axiosInstance.defaults.headers["X-ClientId"] = 2;
    axiosInstance.defaults.headers["X-Culture"] = localeGlobal.currentLocale;
    axiosInstance.defaults.headers["X-Timezone"] = localeGlobal.currentTimezone;

    const accessToken = authGlobal.getAccessToken();
    if (accessToken)
      axiosInstance.defaults.headers["common"]["Authorization"] = `Bearer ${accessToken}`;
  }

  return { send };
}

export function useCancelToken(): [CancelToken, Canceler] {
  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

  return [cancelTokenSource.token, cancelTokenSource.cancel];
}
