import React, { createContext, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment-timezone";
import "moment/locale/tr";
import "moment/locale/ar";

interface ILocaleContext {
  defaultLocale: string;
  currentLocale: string;
  availableLocales: Array<{ id: string; label: string }>;
  updateCurrentLocale: (locale: string) => void;
  currentTimezone: string;
  currentDir: string;
}

const LocaleContext = createContext<ILocaleContext>({} as ILocaleContext);

export function useLocaleGlobal(): ILocaleContext {
  return useContext(LocaleContext);
}

interface IProps {
  children: React.ReactNode;
}

export function LocaleGlobal(props: IProps) {
  const LOCALE_STORAGE_KEY = "locale";
  const DEFAULT_LOCALE = "tr-TR";
  const DEFAULT_LANG = "tr";
  const DEFAULT_DIR = "ltr";
  const availableLocales: Array<{ id: string; label: string; dir: string }> = [
    {
      id: "tr-TR",
      label: "Türkçe",
      dir: "ltr"
    },
    {
      id: "en-US",
      label: "English",
      dir: "ltr"
    },
    {
      id: "ar-AE",
      label: "عربى",
      dir: "rtl"
    }
  ];

  const [currentLocale, setCurrentLocale] = useState<string>(DEFAULT_LOCALE);
  const [currentLang, setCurrentLang] = useState<string>(DEFAULT_LANG);

  useEffect(() => {
    const localeFromStorage = localStorage.getItem(LOCALE_STORAGE_KEY);

    if (localeFromStorage && availableLocales.map((l) => l.id).includes(localeFromStorage)) {
      setCurrentLocale(localeFromStorage);
      setCurrentLang(localeFromStorage.substring(0, 2));
    } else {
      localStorage.setItem(LOCALE_STORAGE_KEY, DEFAULT_LOCALE);
      setCurrentLocale(DEFAULT_LOCALE);
      setCurrentLang(DEFAULT_LANG);
    }
  }, []);

  useEffect(() => {
    moment.locale(currentLang);
  }, [currentLang]);

  function updateCurrentLocale(locale: string) {
    if (availableLocales.map((l) => l.id).includes(locale)) {
      localStorage.setItem(LOCALE_STORAGE_KEY, locale);
      setCurrentLocale(locale);
      setCurrentLang(locale.substring(0, 2));
    } else {
      localStorage.setItem(LOCALE_STORAGE_KEY, DEFAULT_LOCALE);
      setCurrentLocale(DEFAULT_LOCALE);
      setCurrentLang(DEFAULT_LANG);
    }

    window.location.reload();
  }

  const dir = availableLocales.find((l) => l.id === currentLocale)?.dir;

  const value: ILocaleContext = {
    defaultLocale: DEFAULT_LOCALE,
    currentLocale: currentLocale,
    availableLocales: availableLocales,
    updateCurrentLocale: updateCurrentLocale,
    currentTimezone: moment.tz.guess(true),
    currentDir: dir || DEFAULT_DIR
  };

  return (
    <LocaleContext.Provider value={value}>
      <Helmet htmlAttributes={{ lang: currentLang }} bodyAttributes={{ dir: dir || DEFAULT_DIR }} />
      <MuiPickersUtilsProvider utils={MomentUtils} locale={currentLang}>
        {props.children}
      </MuiPickersUtilsProvider>
    </LocaleContext.Provider>
  );
}
