import React, { useEffect, useState } from "react";
import { Breadcrumbs } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

interface ICrumb {
  id: string;
  slug: string;
}

function Breadcrumb() {
  const intl = useIntl();
  const location = useLocation();
  const [crumbs, setCrumbs] = useState<Array<ICrumb>>();

  const rootCrumb: ICrumb = {
    id: "dashboard",
    slug: "/"
  };

  useEffect(() => {
    let finalCrumbs: Array<ICrumb> = [rootCrumb];

    // Son elemanı es geçiyoruz çünkü zaten o sayfa görüntüleniyor olacak.
    // İlk elemanı es geçiyoruz çünkü "" base path'ı döndürüyor.
    let paths = location.pathname.split("/");
    paths.pop();
    paths.splice(0, 1);

    if (paths.length > 0) {
      let dynamicCrumbs: Array<ICrumb> = paths.map((path: string, index: number) => {
        let slug: string = "";

        for (let i = 0; i < index + 1; i++) {
          slug = slug + "/" + paths[i];
        }

        return {
          id: path,
          slug: slug
        };
      });

      finalCrumbs = finalCrumbs.concat(dynamicCrumbs);
    }

    setCrumbs(finalCrumbs);
  }, [location]);

  return (
    <>
      {crumbs && (
        <Breadcrumbs maxItems={4}>
          {crumbs.map((p: ICrumb) => (
            <Link key={p.id} component={RouterLink} to={p.slug}>
              {intl.formatMessage({ id: p.id })}
            </Link>
          ))}
        </Breadcrumbs>
      )}
    </>
  );
}

export default Breadcrumb;
