import React from "react";
import { AppBar, Grid, IconButton, Theme, Toolbar, Typography } from "@material-ui/core";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import { makeStyles } from "@material-ui/styles";
import { useTitleGlobal } from "../../../globals/TitleGlobal/TitleGlobal";
import { useTopbarGlobal } from "../../../globals/TopbarGlobal/TopbarGlobal";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem"
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem"
    }
  }
}));

interface IProps {
  shouldShowMenuButton: boolean;
  onMenuButtonClick: () => void;
}

function Topbar(props: IProps) {
  const classes = useStyles();
  const titleGlobal = useTitleGlobal();
  const topbarGlobal = useTopbarGlobal();

  return (
    <AppBar position="static" variant="outlined">
      <Toolbar variant="regular" disableGutters={true} className={classes.toolbar}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Grid container justify="flex-start" alignItems="center" spacing={1}>
              {props.shouldShowMenuButton && (
                <Grid item>
                  <IconButton color="inherit" onClick={props.onMenuButtonClick}>
                    <MenuRoundedIcon />
                  </IconButton>
                </Grid>
              )}
              <Grid item>
                <Typography variant="h6">{titleGlobal.current}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justify="flex-end" alignItems="center" spacing={0}>
              {topbarGlobal.buttons !== null &&
                topbarGlobal.buttons.length > 0 &&
                topbarGlobal.buttons.map((button: React.ReactElement, index: number) => (
                  <Grid item key={index}>
                    {button}
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Topbar;
