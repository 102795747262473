import React from "react";
import { LoadingGlobal } from "./LoadingGlobal/LoadingGlobal";
import { AuthGlobal } from "./AuthGlobal/AuthGlobal";
import { SnackbarGlobal } from "./SnackbarGlobal/SnackbarGlobal";
import { EnvironmentGlobal } from "./EnvironmentGlobal/EnvironmentGlobal";
import { LocaleGlobal } from "./LocaleGlobal/LocaleGlobal";
import { ThemeGlobal } from "./ThemeGlobal/ThemeGlobal";
import { TitleGlobal } from "./TitleGlobal/TitleGlobal";
import { TopbarGlobal } from "./TopbarGlobal/TopbarGlobal";

interface IProps {
  children: React.ReactElement;
}

function Globals(props: IProps) {
  return (
    <EnvironmentGlobal>
      <LocaleGlobal>
        <ThemeGlobal>
          <TitleGlobal>
            <TopbarGlobal>
              <SnackbarGlobal>
                <LoadingGlobal>
                  <AuthGlobal>{props.children}</AuthGlobal>
                </LoadingGlobal>
              </SnackbarGlobal>
            </TopbarGlobal>
          </TitleGlobal>
        </ThemeGlobal>
      </LocaleGlobal>
    </EnvironmentGlobal>
  );
}

export default Globals;
