import React from "react";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import checkPermission from "../../../utility/helpers/PermissionHelper";

const useStyles = makeStyles({
  nestedListItem: {
    paddingLeft: "4.5rem"
  }
});

export interface IMenuItem {
  id: string;
  slug: string;
  icon: React.ReactElement | null;
  permissions:Array<string>;
  children: Array<IMenuItem> | null;
}

interface IProps {
  item: IMenuItem;
  onSidebarClose: () => void;
  permissions: Array<string>;
  isNestedListOpen: boolean;
  onClick: (id: string) => void;
}

function SidebarMenuItem(props: IProps) {
  const classes = useStyles();
  const intl = useIntl();

  function handleListItemClick(id: string): void {
    if (props.item.children !== null) props.onClick(id);
    else props.onSidebarClose();
  }

function getChildrenPermissions(children:IMenuItem []){

  let _permissions:Array<string> =[];

  children.filter(x=>x.permissions!==null).forEach(x=>{
    if(x.permissions!=null){
      _permissions.push(...x.permissions);
    } 
  });
return _permissions;

}

  let shouldRenderParent =checkPermission(props.permissions,props.item.permissions);
    

  // Alt menu itemlarından dolayı görüntüleme gerekebilir.
  if (!shouldRenderParent && props.item.children !== null) {
    const childrenPermissions:Array<string> = getChildrenPermissions(props.item.children);

    shouldRenderParent =checkPermission(props.permissions,childrenPermissions); //props.permissions.some((p: string) => childrenPermissions.includes(p));
  }

  return (
    <>
      {shouldRenderParent && (
        <>
          <ListItem
            key={props.item.id}
            button={true}
            component={props.item.children === null ? Link : "div"}
            to={props.item.children === null ? props.item.slug : ""}
            onClick={() => handleListItemClick(props.item.id)}
          >
            <ListItemIcon>{props.item.icon}</ListItemIcon>
            <ListItemText disableTypography={true}>
              <Typography variant="body2">
                {intl.formatMessage({ id: props.item.id.toString() })}
              </Typography>
            </ListItemText>
            {props.item.children !== null ? (
              props.isNestedListOpen ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            ) : null}
          </ListItem>
          {props.item.children !== null && (
            <Collapse in={props.isNestedListOpen}>
              <List>
                {props.item.children
                  .filter(
                    (mic: IMenuItem) =>checkPermission(props.permissions,mic.permissions)
                  )
                  .map((mic: IMenuItem) => (
                    <ListItem
                      key={mic.id}
                      button={true}
                      className={classes.nestedListItem}
                      component={Link}
                      to={mic.slug}
                      onClick={props.onSidebarClose}
                    >
                      <ListItemText disableTypography={true}>
                        <Typography variant="body2">
                          {intl.formatMessage({ id: mic.id.toString() })}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  ))}
              </List>
            </Collapse>
          )}
        </>
      )}
    </>
  );
}

export default SidebarMenuItem;
