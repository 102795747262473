import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { useLocaleGlobal } from "../../globals/LocaleGlobal/LocaleGlobal";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  dialogPaper: {
    minWidth: "17rem"
  }
});

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

function LanguageDialog(props: IProps) {
  const intl = useIntl();
  const localeGlobal = useLocaleGlobal();
  const classes = useStyles();

  function handleListItemClick(locale: string): void {
    localeGlobal.updateCurrentLocale(locale);
    props.onClose();
  }

  return (
    <Dialog
      onClose={props.onClose}
      open={props.isOpen}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle>{intl.formatMessage({ id: "CHANGE_LANGUAGE" })}</DialogTitle>
      <DialogContent>
        <List>
          {localeGlobal.availableLocales.map((l) => (
            <ListItem button={true} key={l.id} onClick={() => handleListItemClick(l.id)}>
              <ListItemText>{l.label}</ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}

export default LanguageDialog;
