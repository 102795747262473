import React, { createContext, useContext, useEffect, useState } from "react";

interface IAuthContext {
  getRefreshToken: () => string | null;
  getAccessToken: () => string | null;
  getCompanyId: () => number | null;
  login: (refreshToken: string, accessToken: string, companyId: number) => void;
  logout: () => void;
  isAuthenticated: boolean | null;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export function useAuthGlobal(): IAuthContext {
  return useContext(AuthContext);
}

interface IProps {
  children: React.ReactNode;
}

export function AuthGlobal(props: IProps) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const REFRESH_TOKEN = "rt";
  const ACCESS_TOKEN = "at";
  const COMPANY_ID = "cid";

  useEffect(() => {
    const existingRefreshToken = getRefreshToken();
    const existingAccessToken = getAccessToken();
    const existingCompanyId = getCompanyId();

    if (existingRefreshToken && existingAccessToken && existingCompanyId)
      login(existingRefreshToken, existingAccessToken, existingCompanyId);
    else logout();
  }, []);

  function login(refreshToken: string, accessToken: string, companyId: number): void {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    localStorage.setItem(COMPANY_ID, companyId.toString());
    setIsAuthenticated(true);
  }

  function logout(): void {
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(ACCESS_TOKEN);
    setIsAuthenticated(false);
  }

  function getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  function getAccessToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  function getCompanyId(): number | null {
    return Number(localStorage.getItem(COMPANY_ID));
  }

  const value: IAuthContext = {
    getRefreshToken: getRefreshToken,
    getAccessToken: getAccessToken,
    getCompanyId: getCompanyId,
    login: login,
    logout: logout,
    isAuthenticated: isAuthenticated
  };

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}
