import { ILocale } from "../../../interfaces/ILocale";

export const locales: Array<ILocale> = [
  {
    name: "tr-TR",
    messages: {
      "products:actions": "Ürün İşlemleri",
      "products:list": "Ürün Listesi",
      "products:product-production-quantity-report": "Kahve Üretim Raporu",
      "products:store-stocks": "Depo Stok Raporu",
      "products:expiration-date": "SKT Raporu",
      "products:stock-transfer": "Ürün Virman",
      "products:inventories": "Sayım Modülü",
      "products:expiration-check": "SKT Kontrolü",
      "orders:actions": "Siparişler",
      "orders:active": "Aktif Siparişler",
      "orders:return": "İade Siparişler",
      "orders:returned-empty-carboys": "Boş Damacana İadesi",
      "purchases:actions": "Satın Alma İşlemleri",
      "purchases:new": "Satın Alma Giriş",
      "purchases:dispatches": "İrsaliyeler",
      "purchases:history": "Satın Alma Geçmişi",
      "transfers:actions": "Depo Transferi",
      "transfers:completed":"Tamamlanan Transfer İstekleri",
      "transfers:requests": "Transfer İstekleri",
      "transfers:new-central": "Merkez Depo Transfer",
      "transfers:outgoing": "Giden Depo Transferleri",
      "transfers:incoming": "Gelen Depo Transferleri",
      "transfers:my-stores": "Depolarım Arası Transfer",
      "transfers:dispatches": "İrsaliyeler",
      "payments:actions": "Ödeme İşlemleri",
      "payments:approve": "Ödeme Onayla",
      "payments:report": "Ödeme Raporu",
      "accounting-reports:actions": "Muhasebe İşlemleri",
      "accounting-reports:verifone-transactions": "Verifone Raporu",
      "accounting-reports:cash-count-report:list:read": "Kasa Sayım Tutanakları",
      "accounting-reports:cash-count-report-store-advance": "Depo Avansları",
      "requests:actions": "Talep İşlemleri",
      "requests:new": "Yeni Talep",
      "requests:material": "Yeni Materyal Talebi",
      "requests:store": "Depo Talepleri",
      "requests:purchase-return": "Alım İade ve Bağış Talepleri",
      "requests:completed": "Tamamlanmış Talepler",
      "requests:completed-blockages": "Merkez Depo Blokaj Çıkışları",
      "requests:reconciliation": "Mutabakat Talepleri",
      "requests:slips": "Talep Fişleri",
      "requests:dispatches": "İrsaliyeler",
      "requests:definitions": "Talep Tanımlamaları",
      "requests:reasons": "Talep Giriş Sebepleri",
      "requests:rules": "Otomatik Talep Kuralları",
      "users:actions": "Kullanıcılar",
      "pos-users:actions": "POS Kullanıcıları",
      "cash-count:actions": "Kasa Sayım İşlemleri",
      "MY_ACCOUNT": "Hesabım",
      "CHANGE_LANGUAGE": "Dili Değiştir",
      "LOGOUT": "Çıkış Yap",
      "DARK_THEME": "Gece modu",
      "LIGHT_THEME": "Gündüz modu"
    }
  },
  {
    name: "en-US",
    messages: {
      "products:actions": "Products",
      "products:list": "Products List",
      "products:product-production-quantity-report": "Coffee Production Report",
      "products:store-stocks": "Store Stocks Report",
      "products:expiration-date": "Exp. Date Report",
      "products:stock-transfer": "Stock Transfer",
      "products:inventories": "Inventory Module",
      "products:expiration-check": "Exp. Date Check",
      "orders:actions": "Orders",
      "orders:active": "Active Orders",
      "orders:return": "Returned Orders",
      "orders:returned-empty-carboys": "Returned Empty Carboys",
      "purchases:actions": "Purchases",
      "purchases:new": "New Purchase",
      "purchases:dispatches": "Dispatches",
      "purchases:history": "Purchase History",
      "transfers:actions": "Store Transfers",
      "transfers:completed":"Completed Transfer Requests",
      "transfers:requests": "Transfer Requests",
      "transfers:outgoing": "Outgoing Store Transfers",
      "transfers:incoming": "Incoming Store Transfers",
      "transfers:my-stores": "Transfer Between My Stores",
      "transfers:dispatches": "Dispatches",
      "payments:actions": "Payments",
      "payments:approve": "Approve Payments",
      "payments:report": "Payments Report",
      "accounting-reports:actions": "Accounting Actions",
      "accounting-reports:verifone-transactions": "Verifone Report",
      "accounting-reports:cash-count-report:list:read": "Cash Count Reports",
      "accounting-reports:cash-count-report-store-advance": "Store Advances",
      "requests:actions": "Request Operations",
      "requests:new": "New Request",
      "requests:material": "New Material Request",
      "requests:store": "Store Requests",
      "requests:purchase-return": "Purchase Returns and Donation Requests",
      "requests:completed": "Completed Requests",
      "requests:completed-blockages": "Completed Central Store Blockages",
      "requests:reconciliation": "Reconciliation Requests",
      "requests:slips": "Request Slips",
      "requests:dispatches": "Dispatches",
      "requests:definitions": "Request Definitions",
      "requests:reasons": "Request Reasons",
      "requests:rules": "Auto. Request Rules",
      "users:actions": "Users",
      "pos-users:actions": "POS Users",
      "MY_ACCOUNT": "My Account",
      "CHANGE_LANGUAGE": "Change Language",
      "LOGOUT": "Logout",
      "DARK_THEME": "Dark mode",
      "LIGHT_THEME": "Light mode"
    }
  },
  {
    name: "ar-AE",
    messages: {
      "products:actions": "منتجات",
      "products:list": "قائمة المنتجات",
      "products:product-production-quantity-report": "تقرير إنتاج البن",
      "products:store-stocks": "تقرير مخزون المتجر",
      "products:expiration-date": "تقرير تاريخ انتهاء الصلاحية",
      "products:stock-transfer": "نقل مخزون المنتج",
      "products:inventories": "وحدة الجرد",
      "products:expiration-check": "تحقق من تاريخ انتهاء الصلاحية",
      "orders:actions": "طلب",
      "orders:active": "أوامر نشطة",
      "orders:return": "أوامر مرتجعة",
      "orders:returned-empty-carboys": "عاد قوارير فارغة",
      "purchases:actions": "المشتريات",
      "purchases:new": "شراء جديدة",
      "purchases:dispatches": "بوليصة الشحن",
      "purchases:history": "تاريخ شراء",
      "transfers:actions": "نقل مخزن",
      "transfers:completed":"طلبات التحويل المكتملة",
      "transfers:requests": "طلبات التحويل",
      "transfers:outgoing": "نقل مخزن الصادر",
      "transfers:incoming": "نقل مخزن وارد",
      "transfers:my-stores": "نقل بين متجري",
      "transfers:dispatches": "إرسال",
      "payments:actions": "المدفوعات",
      "payments:approve": "الموافقة على المدفوعات",
      "payments:report": "تقرير المدفوعات",
      "accounting-reports:actions": "إجراءات المحاسبة",
      "accounting-reports:verifone-transactions": "Verifone Report",
      "accounting-reports:cash-count-report:list:read": "تقارير حساب النقد",
      "accounting-reports:cash-count-report-store-advance": "سلف المتجر",
      "requests:actions": "طلبات المتجر",
      "requests:new": "طلب جديد",
      "requests:material": "طلب مواد جديد",
      "requests:store": "طلبات المتجر",
      "requests:purchase-return": "إرجاع المشتريات وطلبات التبرع",
      "requests:completed": "الطلبات المكتملة",
      "requests:completed-blockages": "إتمام عمليات انسداد المخازن المركزية",
      "requests:reconciliation": "طلبات المصالحة",
      "requests:slips": "زلات الطلب",
      "requests:dispatches": "إرسال",
      "requests:definitions": "طلب التعاريف",
      "requests:reasons": "طلب أسباب",
      "requests:rules": "قواعد الطلب التلقائي",
      "users:actions": "المستخدمين",
      "pos-users:actions": "مستخدمو نقاط البيع",
      "MY_ACCOUNT": "حسابي",
      "CHANGE_LANGUAGE": "تغيير اللغة",
      "LOGOUT": "تسجيل خروج",
      "DARK_THEME": "الوضع المظلم",
      "LIGHT_THEME": "وضع الضوء"
    }
  }
];
