import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Sidebar from "./Sidebar/Sidebar";
import Topbar from "./Topbar/Topbar";
import { makeStyles } from "@material-ui/styles";
import { Theme, useMediaQuery, useTheme } from "@material-ui/core";
import Breadcrumb from "./Breadcrumb/Breadcrumb";
import { locales } from "./Breadcrumb/locales";
import LanguageProvider from "../LanguageProvider/LanguageProvider";

interface IProps {
  children: React.ReactElement;
}

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    paddingLeft: "17rem"
  },

  containerPadding: {
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem"
    },
    [theme.breakpoints.up("md")]: {
      padding: "1rem"
    }
  }
}));

function Layout(props: IProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isMediumScreenDown = useMediaQuery(theme.breakpoints.down("md"));
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

  function handleSidebarOpen(): void {
    setIsSidebarOpen(true);
  }

  function handleSidebarClose(): void {
    if (isMediumScreenDown) setIsSidebarOpen(false);
  }

  return (
    <>
      <Sidebar
        isPermanent={!isMediumScreenDown}
        isOpen={isSidebarOpen}
        onClose={handleSidebarClose}
      />
      <Grid container className={isMediumScreenDown ? "" : classes.appBar}>
        <Grid item xs={12}>
          <Topbar shouldShowMenuButton={isMediumScreenDown} onMenuButtonClick={handleSidebarOpen} />
        </Grid>
        <Grid item xs={12} className={classes.containerPadding}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LanguageProvider locales={locales}>
                <Breadcrumb />
              </LanguageProvider>
            </Grid>
            <Grid item xs={12}>
              {props.children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Layout;
