import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { useLocaleGlobal } from "../../globals/LocaleGlobal/LocaleGlobal";
import { ILocale } from "../../interfaces/ILocale";

interface IProps {
  children: React.ReactElement | React.ReactElement[];
  locales: Array<ILocale>;
}

function LanguageProvider(props: IProps) {
  const localeGlobal = useLocaleGlobal();
  const [localizedMessages, setLocalizedMessages] = useState<Record<string, string>>();

  useEffect(() => {
    const langMessages = props.locales.find((m) => m.name === localeGlobal.currentLocale)?.messages;

    setLocalizedMessages(langMessages);
  }, [localeGlobal.currentLocale]);

  return (
    <>
      {localizedMessages && (
        <IntlProvider
          locale={localeGlobal.currentLocale}
          defaultLocale={localeGlobal.defaultLocale}
          messages={localizedMessages}
        >
          {props.children}
        </IntlProvider>
      )}
    </>
  );
}

export default LanguageProvider;
