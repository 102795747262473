import { createTheme } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { blue, grey, pink } from "@material-ui/core/colors";

export interface IUseThemeHelper {
  getTheme: (type: string, direction: string) => Theme;
  getThemes: (direction: string) => Array<Theme>;
  availableThemeTypes: Array<string>;
}

function useThemeHelper(): IUseThemeHelper {
  const lightTheme = createTheme({
    typography: {
      fontFamily: [
        "Fira Sans",
        "Tajawal",
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Arial",
        "sans-serif"
      ].join(","),
      htmlFontSize: 16,
      fontSize: 14
    },

    direction: "ltr",

    palette: {
      type: "light",
      background: {
        default: grey[100]
      },
      primary: {
        main: pink[400]
      },
      secondary: {
        main: blue[400]
      }
    }
  });

  const darkTheme = createTheme({
    typography: {
      fontFamily: [
        "Fira Sans",
        "Tajawal",
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Arial",
        "sans-serif"
      ].join(","),
      htmlFontSize: 16,
      fontSize: 14
    },

    direction: "ltr",

    palette: {
      type: "dark",
      primary: {
        main: pink[400]
      },
      secondary: {
        main: blue[400]
      }
    }
  });

  const themes = [lightTheme, darkTheme];

  function getTheme(type: string, direction: string): Theme {
    let foundTheme = themes.find((t: Theme) => t.palette.type === type);

    if (foundTheme === undefined) {
      lightTheme.direction = direction === "ltr" ? "ltr" : "rtl";
      return lightTheme;
    }

    foundTheme.direction = direction === "ltr" ? "ltr" : "rtl";

    return foundTheme;
  }

  function getThemes(direction: string): Array<Theme> {
    themes.forEach((t: Theme) => (t.direction = direction === "ltr" ? "ltr" : "rtl"));

    return themes;
  }

  const availableThemeTypes: Array<string> = themes.map((t: Theme) => t.palette.type);

  return { getTheme, getThemes, availableThemeTypes };
}

export default useThemeHelper;
