import React, { useEffect, useState } from "react";
import { Divider, Drawer, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { locales } from "./locales";
import LanguageProvider from "../../LanguageProvider/LanguageProvider";
import SidebarBottom from "./SidebarBottom";
import SidebarMenu from "./SidebarMenu";
import SidebarLogo from "./SidebarLogo";
import { useCancelToken } from "../../../services/AxiosService/AxiosService";
import { useAuthGlobal } from "../../../globals/AuthGlobal/AuthGlobal";
import { useAdminService } from "../../../services/AdminService/AdminService";
import { IMenuItem } from "./SidebarMenuItem";
import AppsRoundedIcon from "@material-ui/icons/AppsRounded";
import ShoppingBasketRoundedIcon from "@material-ui/icons/ShoppingBasketRounded";
import AllInboxRoundedIcon from "@material-ui/icons/AllInboxRounded";
import LocalShippingRoundedIcon from "@material-ui/icons/LocalShippingRounded";
import PaymentRoundedIcon from "@material-ui/icons/PaymentRounded";
import LibraryBooksRoundedIcon from "@material-ui/icons/LibraryBooksRounded";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import PhonelinkRingRoundedIcon from "@material-ui/icons/PhonelinkRingRounded";
import AccountBalanceRoundedIcon from "@material-ui/icons/AccountBalanceRounded";

const menuItems: Array<IMenuItem> = [
  {
    id: "products:actions",
    slug: "/product-actions",
    icon: <AppsRoundedIcon />,
    permissions: [],
    children: [
      {
        id: "products:list",
        slug: "/products",
        icon: null,
        permissions: ["products:list-read"],
        children: null
      },
      {
        id: "products:product-production-quantity-report",
        slug: "/product-production-quantity-report",
        icon: null,
        permissions: ["products:list-read"],
        children: null
      },
      {
        id: "products:expiration-date",
        slug: "/expiration-date-report",
        icon: null,
        permissions: ["products:expiration-date-read"],
        children: null
      },
      {
        id: "products:stock-transfer",
        slug: "/stock-transfer",
        icon: null,
        permissions: ["products:stock-transfer-write"],
        children: null
      },
      {
        id: "products:inventories",
        slug: "/inventories",
        icon: null,
        permissions: [
          "products:inventories-read",
          "products:inventories-write",
          "products:inventory-cancel-write",
          "products:inventory-complete-write",
          "products:inventory-approve-write"
        ],
        children: null
      },
      {
        id: "products:expiration-check",
        slug: "/expiration-check",
        icon: null,
        permissions: ["products:expiration-check-read", "products:expiration-check-write"],
        children: null
      }
    ]
  },
  {
    id: "orders:actions",
    slug: "/orders-actions",
    icon: <ShoppingBasketRoundedIcon />,
    permissions: [],
    children: [
      {
        id: "orders:active",
        slug: "/active-orders",
        icon: null,
        permissions: ["orders:active-read", "orders:active-write"],
        children: null
      },
      {
        id: "orders:return",
        slug: "/returned-orders",
        icon: null,
        permissions: ["orders:return-read", "orders:return-write"],
        children: null
      },
      {
        id: "orders:returned-empty-carboys",
        slug: "/returned-empty-carboys",
        icon: null,
        permissions: ["orders:active-read", "orders:active-write"],
        children: null
      }
    ]
  },
  {
    id: "purchases:actions",
    slug: "/purchases-actions",
    icon: <AllInboxRoundedIcon />,
    permissions: [],
    children: [
      {
        id: "purchases:new",
        slug: "/purchases",
        icon: null,
        permissions: ["purchases:new-read", "purchases:new-write"],
        children: null
      },
      {
        id: "purchases:dispatches",
        slug: "/purchase-dispatches",
        icon: null,
        permissions: ["purchases:dispatches-read", "purchases:dispatches-write"],
        children: null
      }
      // İLK ÇIKIŞ GÜNÜ GÖSTERMİYORUZ, SONRADAN YAPILACAK
      // {
      //   id: "purchases:history",
      //   slug: "/purchases-history",
      //   icon: null,
      //   children: null
      // }
    ]
  },
  {
    id: "transfers:actions",
    slug: "/transfers",
    icon: <LocalShippingRoundedIcon />,
    permissions: [],
    children: [
      {
        id: "transfers:requests",
        slug: "/transfers",
        icon: null,
        permissions: ["transfers:requests-read", "transfers:requests-write"],
        children: null
      },
      {
        id: "transfers:completed",
        slug: "/completed-transfers",
        icon: null,
        permissions: ["transfers:requests-read", "transfers:requests-write"],
        children: null
      },
      {
        id: "transfers:new-central",
        slug: "/transfers/new-central",
        icon: null,
        permissions: ["transfers:new-central-write"],
        children: null
      },
      {
        id: "transfers:outgoing",
        slug: "/outgoing-transfers",
        icon: null,
        permissions: ["transfers:outgoing-read", "transfers:outgoing-write"],
        children: null
      },
      {
        id: "transfers:incoming",
        slug: "/incoming-transfers",
        icon: null,
        permissions: ["transfers:incoming-read", "transfers:incoming-write"],
        children: null
      },
      {
        id: "transfers:my-stores",
        slug: "/my-store-transfers",
        icon: null,
        permissions: ["transfers:my-stores-write"],
        children: null
      },
      {
        id: "transfers:dispatches",
        slug: "/transfer-dispatches",
        icon: null,
        permissions: ["transfers:dispatches-read", "transfers:dispatches-write"],
        children: null
      }
    ]
  },
  {
    id: "payments:actions",
    slug: "/payments",
    icon: <PaymentRoundedIcon />,
    permissions: [],
    children: [
      {
        id: "payments:approve",
        slug: "/payments-approve",
        icon: null,
        permissions: [
          "payments:approve-read",
          "payments:approve-write",
          "payments:correction-write"
        ],
        children: null
      },
      {
        id: "payments:report",
        slug: "/payments-report",
        icon: null,
        permissions: ["payments:report-read"],
        children: null
      }
    ]
  },
  {
    id: "accounting-reports:actions",
    slug: "/verifone",
    icon: <PaymentRoundedIcon />,
    permissions: [],
    children: [
      {
        id: "accounting-reports:verifone-transactions",
        slug: "/verifone-report",
        icon: null,
        permissions: ["accounting-reports:verifone-transactions"],
        children: null
      },
      {
        id: "accounting-reports:cash-count-report:list:read",
        slug: "/cash-count-reports",
        icon: null,
        permissions: [
          "accounting-reports:cash-count-report-read",
          "accounting-reports:cash-count-report-create-edit-write",
          "accounting-reports:cash-count-report-approve-decline-write"
        ],
        children: null
      },
      {
        id: "accounting-reports:cash-count-report-store-advance",
        slug: "/cash-count-report-store-advance",
        icon: null,
        permissions: [
          "accounting-reports:cash-count-report-store-advance-read",
          "accounting-reports:cash-count-report-store-advance-write"
        ],
        children: null
      }
    ]
  },
  {
    id: "requests:actions",
    slug: "/requests-actions",
    icon: <LibraryBooksRoundedIcon />,
    permissions: [],
    children: [
      {
        id: "requests:new",
        slug: "/requests/add",
        icon: null,
        permissions: ["requests:new-write"],
        children: null
      },
      {
        id: "requests:material",
        slug: "/requests/material",
        icon: null,
        permissions: ["requests:material-write"],
        children: null
      },
      {
        id: "requests:store",
        slug: "/requests",
        icon: null,
        permissions: ["requests:store-read", "requests:store-write"],
        children: null
      },
      {
        id: "requests:purchase-return",
        slug: "/requests/purchase-return",
        icon: null,
        permissions: ["requests:purchase-return-read", "requests:purchase-return-write"],
        children: null
      },
      {
        id: "requests:completed",
        slug: "/requests/completed",
        icon: null,
        permissions: ["requests:completed-read", "requests:completed-write"],
        children: null
      },
      {
        id: "requests:completed-blockages",
        slug: "/requests/completed-blockage",
        icon: null,
        permissions:["requests:completed-read","requests:completed-write"],
        children: null
      },
      {
        id: "requests:reconciliation",
        slug: "/requests/reconciliation",
        icon: null,
        permissions: ["requests:reconciliation-read", "requests:reconciliation-write"],
        children: null
      },
      {
        id: "requests:slips",
        slug: "/requests/slips",
        icon: null,
        permissions: ["requests:slips-read", "requests:slips-write"],
        children: null
      },
      {
        id: "requests:dispatches",
        slug: "/requests/dispatches",
        icon: null,
        permissions: ["requests:dispatches-read"],
        children: null
      }
    ]
  },
  {
    id: "requests:definitions",
    slug: "/requests-definitions",
    icon: <LibraryBooksRoundedIcon />,
    permissions: [],
    children: [
      {
        id: "requests:reasons",
        slug: "/requests/reasons",
        icon: null,
        permissions: ["requests:reasons-read", "requests:reasons-write"],
        children: null
      },
      {
        id: "requests:rules",
        slug: "/requests/rules",
        icon: null,
        permissions: ["requests:rules-read", "requests:rules-write"],
        children: null
      }
    ]
  },
  {
    id: "users:actions",
    slug: "/users",
    icon: <GroupRoundedIcon />,
    permissions: ["users:actions-read", "users:actions-write"],
    children: null
  },
  {
    id: "pos-users:actions",
    slug: "/pos-users",
    icon: <PhonelinkRingRoundedIcon />,
    permissions: ["pos-users:actions-read", "pos-users:actions-write"],
    children: null
  }
];

const useStyles = makeStyles({
  drawerPaper: {
    width: "17rem"
  },

  h100: {
    height: "100%"
  },

  alignFlexStart: {
    alignSelf: "flex-start"
  },

  alignFlexEnd: {
    alignSelf: "flex-end"
  }
});

interface IProps {
  isOpen: boolean;
  isPermanent: boolean;
  onClose: () => void;
}

function Sidebar(props: IProps) {
  const [permissions, setPermissions] = useState<Array<string> | null>(null);
  const [openMenuItemId, setOpenMenuItemId] = useState<string>("");

  const classes = useStyles();
  const adminService = useAdminService();
  const authGlobal = useAuthGlobal();
  const [token, cancelToken] = useCancelToken();

  useEffect(() => {
    adminService
      .getPermissions(token)
      .then((r: string[]) => setPermissions(r))
      .catch(() => authGlobal.logout());

    return function cleanup() {
      cancelToken();
    };
  }, []);

  return (
    <LanguageProvider locales={locales}>
      <Drawer
        variant={props.isPermanent ? "permanent" : "temporary"}
        open={props.isPermanent ? true : props.isOpen}
        onClose={props.onClose}
        elevation={0}
        anchor="left"
        PaperProps={{ className: classes.drawerPaper }}
      >
        <Grid container className={classes.h100}>
          <Grid item xs={12} className={classes.alignFlexStart}>
            <Grid container>
              <Grid item xs={12}>
                <SidebarLogo onSidebarClose={props.onClose} />
              </Grid>
              <Grid item xs={12}>
                <SidebarMenu
                  onSidebarClose={props.onClose}
                  permissions={permissions}
                  menuItems={menuItems}
                  openMenuItemId={openMenuItemId}
                  onMenuItemClick={setOpenMenuItemId}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.alignFlexEnd}>
            <Divider variant="fullWidth" />
            <SidebarBottom onSidebarClose={props.onClose} />
          </Grid>
        </Grid>
      </Drawer>
    </LanguageProvider>
  );
}

export default Sidebar;
