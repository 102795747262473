import React, { createContext, useContext } from "react";
import { useAxiosService } from "../AxiosService/AxiosService";
import axios, { AxiosRequestConfig, CancelToken } from "axios";
import { useEnvironmentGlobal } from "../../globals/EnvironmentGlobal/EnvironmentGlobal";
import queryString from "query-string";
import qs from "qs";
import {
  IAddCashCountReportResponse,
  IAddOrUpdateStoreInventoryCollectionProductResponse,
  IAddWarehouseTransferProductResponse,
  IBulkPartiallySaveStoreTransferResponse,
  ICancelStoreTransfersServiceResponse,
  ICheckSupplierOrderCollectionsResponse,
  IGetAllPermissionsWithUserAssignmentsResponse,
  IGetAllStoresWithUserAssignmentsResponse,
  IGetAllSuppliersResponse,
  IGetCashCountReportResponse,
  IGetCashCountReportsResponse,
  IGetCompletedBlockageRequestsResponse,
  IGetCompletedSupplierOrderCollectionResponse,
  IGetCurrencyUnit,
  IGetMyInfoResponse,
  IGetOrderCollectionResponse,
  IGetOrderRefundCollectionResponse,
  IGetPosUserResponse,
  IGetPosUsersResponse,
  IGetReconciliationRequestDetailResponseModel,
  IGetReconciliationRequestsResponse,
  IGetRequestApprovalRulesResponse,
  IGetRequestDispatchesResponse,
  IGetRequestDispatchResponse,
  IGetRequestInfoForUpdateResponse,
  IGetRequestReasonsResponse,
  IGetRequestSlipResponse,
  IGetRequestSlipsResponse,
  IGetRequestsResponse,
  IGetSampleResponse,
  IGetStoreActiveOrdersResponse,
  IGetStoreApprovedByDetailedPaymentResportToDownloadResponse,
  IGetStoreCashCountReportConfigsResponse,
  IGetStoreCompletedInventoryToDownloadResponse,
  IGetStoreCompletedTransferResponse,
  IGetStoreDetailedPaymentReportResponse,
  IGetStoreDetailedPaymentReportToDownloadResponse,
  IGetStoreExpirationCheckProductDetailResponse,
  IGetStoreExpirationCheckReportResponse,
  IGetStoreExpirationChecksResponse,
  IGetStoreExpirationCheckWithProductsResponse,
  IGetStoreIncomingTransferCollectionResponse,
  IGetStoreInventoriesResponse,
  IGetStoreInventoryCollectionProductsResponse,
  IGetStoreInventoryCollectionResponse,
  IGetStoreInventoryCollectionsResponse,
  IGetStoreInventoryPreviewResponse,
  IGetStoreInventoryPreviewToDownloadResponse,
  IGetStoreInventoryResponse,
  IGetStoreOfflineOrdersResponse,
  IGetStoreOutgoingTransferResponse,
  IGetStorePaymentReportResponse,
  IGetStorePaymentReportToDownloadResponse,
  IGetStoreProductExpirationDateReportResponse,
  IGetStoreProductExpirationDateReportToDownloadResponse,
  IGetStoreProductResponse,
  IGetStoreProductsResponse,
  IGetStoreProductsToDownloadResponse,
  IGetStoreProductSuppliersResponse,
  IGetStoreReturnableEmptyCarboysResponse,
  IGetStoreReturnedOrdersResponse,
  IGetStoreStockDifferenceToDownloadResponse,
  IGetStoreSupplierOrderDispatchesResponse,
  IGetStoreSupplierOrdersResponse,
  IGetStoreTransferDescriptionsResponse,
  IGetStoreTransferDispatchesResponse,
  IGetStoreTransferDispatchResponse,
  IGetStoreTransferProductByCodeResponse,
  IGetStoreTransferResponse,
  IGetStoreTransfersResponse,
  IGetSupplierOrderCollectionResponse,
  IGetSupplierOrderProductByCodeResponse,
  IGetUserResponse,
  IGetUsersResponse,
  IGetUserStoresResponse,
  IGetVerifoneTransactionLogsReportResponse,
  IGetVerifoneTransactionLogsReportResponseModel,
  IGetWarehouseProductResponse,
  IGetWarehouseTransferResponse,
  ISaveStoreOutgoingTransferCollectionResponse,
  ISaveWarehouseTransferResponse,
  ISearchProductsResponse,
  IGetStoreProductProductionQuantityReportResponse,
  IGetStoreProductProductionQuantityReportDownloadResponse,
  IBulkAddAndApproveRequestResponse
} from "./responses";
import {
  IAddCashCountReportModel,
  IAddCurrencyUnitQuantityModel,
  IAddNewUserModel,
  IAddOrderCollectionProductModel,
  IAddOrderCollectionWasteProductModel,
  IAddOrUpdateStoreInventoryCollectionProductModel,
  IAddRequestApprovalRuleModel,
  IAddRequestReasonModel,
  IAddRequestsModel,
  IAddStoreCashCountReportConfigModel,
  IAddStoreExpirationCheckModel,
  IAddStoreInventoryModel,
  IAddStoreInventoryProcessedStocksModel,
  IAddStoreTransferModel,
  IAddStoreTransferProductModel,
  IAddStoreTransferProductsModel,
  IAddStoreTransferWithProductModel,
  IAddWarehouseTransferModel,
  IAddWarehouseTransferProductModel,
  IApproveCashCountReportModel,
  IApprovePurchaseReturnRequestsModel,
  IApproveReconciliationRequestsModel,
  IApproveRequestsModel,
  IApproveStoreInventoryModel,
  IBulkSaveStoreTransferModel,
  ICancelRequestsModel,
  ICancelStoreInventoryCollectionModel,
  ICancelStoreInventoryModel,
  ICancelStoreTransfersModel,
  ICheckAddStoreTransferWithProductModel,
  ICheckSupplierOrderCollectionsModel,
  ICompleteStoreInventoryCollectionModel,
  ICompleteStoreInventoryModel,
  IDeclineCashCountReportModel,
  IDeleteCurrencyUnitQuantityModel,
  IDeleteOrderCollectionModel,
  IDeleteOrderCollectionProductModel,
  IDeleteRequestApprovalRuleModel,
  IDeleteRequestReasonModel,
  IDeleteStoreInventoryCollectionProductModel,
  IDeleteStoreInventoryCollectionProductsModel,
  IDeleteStoreTransferProductModel,
  IDeleteStoreTransferProductsModel,
  IDeleteUserModel,
  IDeleteWarehouseTransferModel,
  IDeleteWarehouseTransferProductModel,
  IGetAllPermissionsWithUserAssignmentsModel,
  IGetAllStoresWithUserAssignmentsModel,
  IGetCashCountReportModel,
  IGetCashCountReportsModel,
  IGetCompletedBlockageRequestsModel,
  IGetCompletedSupplierOrderCollectionModel,
  IGetDetailedApprovedbyReportDownloadModel,
  IGetOrderCollectionModel,
  IGetOrderRefundCollectionModel,
  IGetPosUserModel,
  IGetPosUsersModel,
  IGetReconciliationRequestDetailsModel,
  IGetReconciliationRequestsModel,
  IGetRequestApprovalRulesModel,
  IGetRequestDispatchesModel,
  IGetRequestDispatchModel,
  IGetRequestInfoForUpdateModel,
  IGetRequestSlipModel,
  IGetRequestSlipsModel,
  IGetRequestsModel,
  IGetStoreActiveOrdersModel,
  IGetStoreAllInventoryCollectionProductsModel,
  IGetStoreCashCountReportConfigsModel,
  IGetStoreCompletedInventoryToDownloadModel,
  IGetStoreCompletedTransferModel,
  IGetStoreCompletedTransfersFilterModel,
  IGetStoreDetailedPaymentReportModel,
  IGetStoreDetailedPaymentReportToDownloadModel,
  IGetStoreExpirationCheckProductDetailModel,
  IGetStoreExpirationCheckReportModel,
  IGetStoreExpirationChecksModel,
  IGetStoreExpirationCheckWithProductsModel,
  IGetStoreIncomingTransferCollectionModel,
  IGetStoreIncomingTransfersModel,
  IGetStoreInventoriesModel,
  IGetStoreInventoryCollectionModel,
  IGetStoreInventoryCollectionProductsModel,
  IGetStoreInventoryCollectionsModel,
  IGetStoreInventoryModel,
  IGetStoreInventoryPreviewModel,
  IGetStoreInventoryPreviewToDownloadModel,
  IGetStoreOfflineOrdersModel,
  IGetStoreOutgoingTransferModel,
  IGetStoreOutgoingTransfersModel,
  IGetStorePaymentReportModel,
  IGetStorePaymentReportToDownloadModel,
  IGetStoreProductExpirationDateReportModel,
  IGetStoreProductExpirationDateReportToDownloadModel,
  IGetStoreProductModel,
  IGetStoreProductsModel,
  IGetStoreProductsToDownloadModel,
  IGetStoreProductSuppliersModel,
  IGetStoreReturnableEmptyCarboysModel,
  IGetStoreReturnedOrdersModel,
  IGetStoreStockDifferenceToDownloadModel,
  IGetStoreSupplierOrderDispatchesModel,
  IGetStoreSupplierOrdersModel,
  IGetStoreTransferDispatchesModel,
  IGetStoreTransferDispatchModel,
  IGetStoreTransferModel,
  IGetStoreTransferProductByCodeModel,
  IGetStoreTransfersFilterModel,
  IGetSupplierOrderCollectionModel,
  IGetSupplierOrderProductByCodeModel,
  IGetUserModel,
  IGetUsersModel,
  IGetUserStoresModel,
  IGetVerifoneTransactionLogsReportModel,
  IGetVerifoneTransactionLogsReportToDownloadModel,
  IGetWarehouseProductModel,
  IRevertCompletedRequest,
  ISaveOfflineOrderPaymentModel,
  ISaveOrderCollectionModel,
  ISaveOrderRefundModel,
  ISaveStoreIncomingTransferCollectionModel,
  ISaveStoreOutgoingTransferCollectionModel,
  ISaveStoreTransferModel,
  ISaveSupplierOrderCollectionModel,
  ISaveUserPermissionsModel,
  ISaveUserStoresModel,
  ISaveWarehouseTransferModel,
  ISearchProductsModel,
  ISendCashCountReportForApprovalModel,
  IStartOrderRefundModel,
  IStartStoreInventoryCollectionModel,
  IStopStoreInventoryCollectionModel,
  ITransferStockBetweenProductsModel,
  IUpdateCashCountReportModel,
  IUpdateOrderCorrectionPaymentModel,
  IUpdateRequestModel,
  IUpdateRequestReasonModel,
  IUpdateReturnableEmptyCarboyModel,
  IUpdateStoreCashCountReportConfigModel,
  IUpdateStoreExpirationCheckProductModel,
  IUpdateStoreExpirationCheckStatusModel,
  IUpdateStoreInventoryCollectionProductModel,
  IUpdateStoreInventoryStatusModel,
  IUpdateStoreTransferModel,
  IUpdateStoreTransferProductModel,
  IUpdateSupplierCollectionModel,
  IUpdateSupplierCollectionProductModel,
  IUpdateUserModel,
  IGetStoreTransferDescriptionsModel,
  IGetStoreIncomingTransferCollectionIdByPalletIdModel,
  IGetRequestsToDownloadModel,
  IGetPurchaseReturnRequestsToDownloadModel,
  IGetStoreProductProductionQuantityReportModel,
  IGetStoreProductProductionQuantityReportDownloadModel,
  IBulkAddAndApproveRequestModel
} from "./models";

interface IAdminServiceContext {
  // Sample
  getSample: (token: CancelToken) => Promise<IGetSampleResponse>;

  // Users
  getPermissions: (token: CancelToken) => Promise<Array<string>>;
  getUsers: (token: CancelToken, model: IGetUsersModel) => Promise<IGetUsersResponse>;
  getMyInfo: (token: CancelToken) => Promise<IGetMyInfoResponse>;
  getMyStores: (token: CancelToken) => Promise<Array<IGetUserStoresResponse>>;
  getUserStores: (
    token: CancelToken,
    model: IGetUserStoresModel
  ) => Promise<Array<IGetUserStoresResponse>>;
  getUser: (token: CancelToken, model: IGetUserModel) => Promise<IGetUserResponse>;
  addNewUser: (token: CancelToken, model: IAddNewUserModel) => Promise<boolean>;
  updateUser: (token: CancelToken, model: IUpdateUserModel) => Promise<boolean>;
  deleteUser: (token: CancelToken, model: IDeleteUserModel) => Promise<boolean>;
  getAllStoresWithUserAssignments: (
    token: CancelToken,
    model: IGetAllStoresWithUserAssignmentsModel
  ) => Promise<Array<IGetAllStoresWithUserAssignmentsResponse>>;
  saveUserStores: (token: CancelToken, model: ISaveUserStoresModel) => Promise<boolean>;
  getAllPermissionsWithUserAssignments: (
    token: CancelToken,
    model: IGetAllPermissionsWithUserAssignmentsModel
  ) => Promise<IGetAllPermissionsWithUserAssignmentsResponse>;
  saveUserPermissions: (token: CancelToken, model: ISaveUserPermissionsModel) => Promise<boolean>;

  // Stores
  getStoreProducts: (
    token: CancelToken,
    model: IGetStoreProductsModel
  ) => Promise<IGetStoreProductsResponse>;
  getStoreProductsToDownload: (
    token: CancelToken,
    model: IGetStoreProductsToDownloadModel
  ) => Promise<Array<IGetStoreProductsToDownloadResponse>>;
  getStoreProductExpirationDateReport: (
    token: CancelToken,
    model: IGetStoreProductExpirationDateReportModel
  ) => Promise<IGetStoreProductExpirationDateReportResponse>;
  getStoreProductExpirationDateReportToDownload: (
    token: CancelToken,
    model: IGetStoreProductExpirationDateReportToDownloadModel
  ) => Promise<Array<IGetStoreProductExpirationDateReportToDownloadResponse>>;

  getStorePaymentReport: (
    token: CancelToken,
    model: IGetStorePaymentReportModel
  ) => Promise<IGetStorePaymentReportResponse>;

  getVerifoneTransactionLogs: (
    token: CancelToken,
    model: IGetVerifoneTransactionLogsReportModel
  ) => Promise<IGetVerifoneTransactionLogsReportResponse>;

  getVerifoneTransactionLogsReportToDownload: (
    token: CancelToken,
    model: IGetVerifoneTransactionLogsReportToDownloadModel
  ) => Promise<Array<IGetVerifoneTransactionLogsReportResponseModel>>;

  getStorePaymentReportToDownload: (
    token: CancelToken,
    model: IGetStorePaymentReportToDownloadModel
  ) => Promise<Array<IGetStorePaymentReportToDownloadResponse>>;

  getStoreDetailedPaymentReport: (
    token: CancelToken,
    model: IGetStoreDetailedPaymentReportModel
  ) => Promise<IGetStoreDetailedPaymentReportResponse>;
  getStoreDetailedPaymentReportToDownload: (
    token: CancelToken,
    model: IGetStoreDetailedPaymentReportToDownloadModel
  ) => Promise<Array<IGetStoreDetailedPaymentReportToDownloadResponse>>;
  getDetailedApprovedByPaymentReportToDownload: (
    token: CancelToken,
    model: IGetDetailedApprovedbyReportDownloadModel
  ) => Promise<Array<IGetStoreApprovedByDetailedPaymentResportToDownloadResponse>>;

  getStoreProduct: (
    token: CancelToken,
    model: IGetStoreProductModel
  ) => Promise<IGetStoreProductResponse>;
  getWarehouseProduct: (
    token: CancelToken,
    model: IGetWarehouseProductModel
  ) => Promise<IGetWarehouseProductResponse>;
  getStoreProductSuppliers: (
    token: CancelToken,
    model: IGetStoreProductSuppliersModel
  ) => Promise<Array<IGetStoreProductSuppliersResponse>>;
  getStoreActiveOrders: (
    token: CancelToken,
    model: IGetStoreActiveOrdersModel
  ) => Promise<IGetStoreActiveOrdersResponse>;
  getStoreInventories: (
    token: CancelToken,
    model: IGetStoreInventoriesModel
  ) => Promise<IGetStoreInventoriesResponse>;
  addStoreInventory: (token: CancelToken, model: IAddStoreInventoryModel) => Promise<boolean>;
  getStoreInventoryCollections: (
    token: CancelToken,
    model: IGetStoreInventoryCollectionsModel
  ) => Promise<Array<IGetStoreInventoryCollectionsResponse>>;
  getStoreInventoryCollection: (
    token: CancelToken,
    model: IGetStoreInventoryCollectionModel
  ) => Promise<IGetStoreInventoryCollectionResponse>;
  getStoreInventory: (
    token: CancelToken,
    model: IGetStoreInventoryModel
  ) => Promise<IGetStoreInventoryResponse>;
  getStoreInventoryPreview: (
    token: CancelToken,
    model: IGetStoreInventoryPreviewModel
  ) => Promise<IGetStoreInventoryPreviewResponse>;
  getStoreInventoryPreviewToDownload: (
    token: CancelToken,
    model: IGetStoreInventoryPreviewToDownloadModel
  ) => Promise<Array<IGetStoreInventoryPreviewToDownloadResponse>>;
  getStoreCompletedInventoryToDownload: (
    token: CancelToken,
    model: IGetStoreCompletedInventoryToDownloadModel
  ) => Promise<Array<IGetStoreCompletedInventoryToDownloadResponse>>;
  addStoreInventoryProcessedStocks: (
    token: CancelToken,
    model: IAddStoreInventoryProcessedStocksModel
  ) => Promise<string | null>;
  getStoreStockDifferenceToDownload: (
    token: CancelToken,
    model: IGetStoreStockDifferenceToDownloadModel
  ) => Promise<Array<IGetStoreStockDifferenceToDownloadResponse>>;
  startStoreInventoryCollection: (
    token: CancelToken,
    model: IStartStoreInventoryCollectionModel
  ) => Promise<boolean>;
  stopStoreInventoryCollection: (
    token: CancelToken,
    model: IStopStoreInventoryCollectionModel
  ) => Promise<boolean>;
  completeStoreInventoryCollection: (
    token: CancelToken,
    model: ICompleteStoreInventoryCollectionModel
  ) => Promise<boolean>;
  cancelStoreInventoryCollection: (
    token: CancelToken,
    model: ICancelStoreInventoryCollectionModel
  ) => Promise<boolean>;
  getStoreAllInventoryCollectionProducts: (
    token: CancelToken,
    model: IGetStoreAllInventoryCollectionProductsModel
  ) => Promise<Array<IGetStoreInventoryCollectionProductsResponse>>;
  getStoreInventoryCollectionProducts: (
    token: CancelToken,
    model: IGetStoreInventoryCollectionProductsModel
  ) => Promise<Array<IGetStoreInventoryCollectionProductsResponse>>;
  deleteStoreInventoryCollectionProducts: (
    token: CancelToken,
    model: IDeleteStoreInventoryCollectionProductsModel
  ) => Promise<boolean>;
  addOrUpdateStoreInventoryCollectionProduct: (
    token: CancelToken,
    model: IAddOrUpdateStoreInventoryCollectionProductModel
  ) => Promise<IAddOrUpdateStoreInventoryCollectionProductResponse>;
  updateStoreInventoryCollectionProduct: (
    token: CancelToken,
    model: IUpdateStoreInventoryCollectionProductModel
  ) => Promise<boolean>;
  updateStoreInventoryStatus: (
    token: CancelToken,
    model: IUpdateStoreInventoryStatusModel
  ) => Promise<boolean>;
  completeStoreInventory: (
    token: CancelToken,
    model: ICompleteStoreInventoryModel
  ) => Promise<boolean>;
  cancelStoreInventory: (token: CancelToken, model: ICancelStoreInventoryModel) => Promise<boolean>;
  approveStoreInventory: (
    token: CancelToken,
    model: IApproveStoreInventoryModel
  ) => Promise<boolean>;
  deleteStoreInventoryCollectionProduct: (
    token: CancelToken,
    model: IDeleteStoreInventoryCollectionProductModel
  ) => Promise<boolean>;
  getStoreReturnedOrders: (
    token: CancelToken,
    model: IGetStoreReturnedOrdersModel
  ) => Promise<IGetStoreReturnedOrdersResponse>;
  getStoreOfflineOrders: (
    token: CancelToken,
    model: IGetStoreOfflineOrdersModel
  ) => Promise<IGetStoreOfflineOrdersResponse>;
  addWarehouseTransfer: (
    token: CancelToken,
    model: IAddWarehouseTransferModel
  ) => Promise<IGetWarehouseTransferResponse>;
  addWarehouseTransferProduct: (
    token: CancelToken,
    model: IAddWarehouseTransferProductModel
  ) => Promise<IAddWarehouseTransferProductResponse>;
  deleteWarehouseTransfer: (
    token: CancelToken,
    model: IDeleteWarehouseTransferModel
  ) => Promise<boolean>;
  deleteWarehouseTransferProduct: (
    token: CancelToken,
    model: IDeleteWarehouseTransferProductModel
  ) => Promise<IGetWarehouseTransferResponse>;
  saveWarehouseTransfer: (
    token: CancelToken,
    model: ISaveWarehouseTransferModel
  ) => Promise<ISaveWarehouseTransferResponse>;
  getStoreSupplierOrders: (
    token: CancelToken,
    model: IGetStoreSupplierOrdersModel
  ) => Promise<IGetStoreSupplierOrdersResponse>;
  getStoreTransfers: (
    token: CancelToken,
    model: IGetStoreTransfersFilterModel
  ) => Promise<IGetStoreTransfersResponse>;
  getStoreCompletedTransfers: (
    token: CancelToken,
    model: IGetStoreCompletedTransfersFilterModel
  ) => Promise<IGetStoreTransfersResponse>;
  getStoreCompletedTransfer: (
    token: CancelToken,
    model: IGetStoreCompletedTransferModel
  ) => Promise<IGetStoreCompletedTransferResponse>;
  addStoreTransfer: (
    token: CancelToken,
    model: IAddStoreTransferModel
  ) => Promise<IGetStoreTransferResponse>;
  getStoreTransfer: (
    token: CancelToken,
    model: IGetStoreTransferModel
  ) => Promise<IGetStoreTransferResponse>;
  addStoreTransferProduct: (
    token: CancelToken,
    model: IAddStoreTransferProductModel
  ) => Promise<IGetStoreTransferResponse>;
  addStoreTransferProducts: (
    token: CancelToken,
    model: IAddStoreTransferProductsModel
  ) => Promise<IGetStoreTransferResponse>;
  deleteStoreTransferProduct: (
    token: CancelToken,
    model: IDeleteStoreTransferProductModel
  ) => Promise<IGetStoreTransferResponse>;
  deleteStoreTransferProducts: (
    token: CancelToken,
    model: IDeleteStoreTransferProductsModel
  ) => Promise<IGetStoreTransferResponse>;
  saveStoreTransfer: (token: CancelToken, model: ISaveStoreTransferModel) => Promise<boolean>;
  bulkSaveStoreTransfer: (
    token: CancelToken,
    model: IBulkSaveStoreTransferModel
  ) => Promise<boolean>;
  partiallySaveStoreTransfer: (token: CancelToken, model: ISaveStoreTransferModel) => Promise<boolean>;
  bulkPartiallySaveStoreTransfer: (
    token: CancelToken,
    model: IBulkSaveStoreTransferModel
  ) => Promise<IBulkPartiallySaveStoreTransferResponse>;
  updateStoreTransfer: (
    token: CancelToken,
    model: IUpdateStoreTransferModel
  ) => Promise<IGetStoreTransferResponse>;
  updateStoreTransferProduct: (
    token: CancelToken,
    model: IUpdateStoreTransferProductModel
  ) => Promise<IGetStoreTransferResponse>;
  getStoreOutgoingTransfers: (
    token: CancelToken,
    model: IGetStoreOutgoingTransfersModel
  ) => Promise<IGetStoreTransfersResponse>;
  getStoreOutgoingTransfer: (
    token: CancelToken,
    model: IGetStoreOutgoingTransferModel
  ) => Promise<IGetStoreOutgoingTransferResponse>;
  getStoreTransferProductByCode: (
    token: CancelToken,
    model: IGetStoreTransferProductByCodeModel
  ) => Promise<IGetStoreTransferProductByCodeResponse>;
  saveStoreOutgoingTransferCollection: (
    token: CancelToken,
    model: ISaveStoreOutgoingTransferCollectionModel
  ) => Promise<ISaveStoreOutgoingTransferCollectionResponse>;
  getStoreIncomingTransfers: (
    token: CancelToken,
    model: IGetStoreIncomingTransfersModel
  ) => Promise<IGetStoreTransfersResponse>;
  getStoreIncomingTransferCollection: (
    token: CancelToken,
    model: IGetStoreIncomingTransferCollectionModel
  ) => Promise<IGetStoreIncomingTransferCollectionResponse>;
  getStoreIncomingTransferCollectionIdByPalletId: (
    token: CancelToken,
    model: IGetStoreIncomingTransferCollectionIdByPalletIdModel
  ) => Promise<number>;
  saveStoreIncomingTransferCollection: (
    token: CancelToken,
    model: ISaveStoreIncomingTransferCollectionModel
  ) => Promise<boolean>;
  transferStockBetweenProducts: (
    token: CancelToken,
    model: ITransferStockBetweenProductsModel
  ) => Promise<boolean>;
  addStoreTransfersWithProducts: (
    token: CancelToken,
    model: Array<IAddStoreTransferWithProductModel>
  ) => Promise<boolean>;
  checkAddStoreTransfersWithProducts: (
    token: CancelToken,
    model: Array<ICheckAddStoreTransferWithProductModel>
  ) => Promise<Array<number>>;
  getStoreSupplierOrderDispatches: (
    token: CancelToken,
    model: IGetStoreSupplierOrderDispatchesModel
  ) => Promise<IGetStoreSupplierOrderDispatchesResponse>;
  cancelStoreTransfer: (token: CancelToken, transferId: number) => Promise<boolean>;
  getStoreReturnableEmptyCarboys: (
    token: CancelToken,
    model: IGetStoreReturnableEmptyCarboysModel
  ) => Promise<IGetStoreReturnableEmptyCarboysResponse>;
  updateStoreReturnableEmptyCarboy: (
    token: CancelToken,
    model: IUpdateReturnableEmptyCarboyModel
  ) => Promise<boolean>;
  cancelStoreTransfers: (
    token: CancelToken,
    model: ICancelStoreTransfersModel
  ) => Promise<ICancelStoreTransfersServiceResponse>;
  getStoreTransferDescriptions: (
    token: CancelToken,
    model: IGetStoreTransferDescriptionsModel
  ) => Promise<Array<IGetStoreTransferDescriptionsResponse>>;

  // Orders
  getOrderCollection: (
    token: CancelToken,
    model: IGetOrderCollectionModel
  ) => Promise<IGetOrderCollectionResponse>;
  deleteOrderCollection: (
    token: CancelToken,
    model: IDeleteOrderCollectionModel
  ) => Promise<IGetOrderCollectionResponse>;
  deleteOrderCollectionProduct: (
    token: CancelToken,
    model: IDeleteOrderCollectionProductModel
  ) => Promise<IGetOrderCollectionResponse>;
  addOrderCollectionProduct: (
    token: CancelToken,
    model: IAddOrderCollectionProductModel
  ) => Promise<IGetOrderCollectionResponse>;
  addOrderCollectionWasteProduct: (
    token: CancelToken,
    model: IAddOrderCollectionWasteProductModel
  ) => Promise<IGetOrderCollectionResponse>;
  saveOrderCollection: (token: CancelToken, model: ISaveOrderCollectionModel) => Promise<boolean>;
  getOrderRefundCollection: (
    token: CancelToken,
    model: IGetOrderRefundCollectionModel
  ) => Promise<IGetOrderRefundCollectionResponse>;
  saveOrderRefund: (token: CancelToken, model: ISaveOrderRefundModel) => Promise<boolean>;
  startOrderRefund: (token: CancelToken, model: IStartOrderRefundModel) => Promise<boolean>;
  saveOfflineOrderPayment: (
    token: CancelToken,
    model: ISaveOfflineOrderPaymentModel
  ) => Promise<boolean>;

  // Pos Users
  getPosUsers: (token: CancelToken, model: IGetPosUsersModel) => Promise<IGetPosUsersResponse>;
  getPosUser: (token: CancelToken, model: IGetPosUserModel) => Promise<IGetPosUserResponse>;

  // Requests
  getRequests: (token: CancelToken, model: IGetRequestsModel) => Promise<IGetRequestsResponse>;
  getRequestsToDownload: (token: CancelToken, model: IGetRequestsToDownloadModel) => Promise<IGetRequestsResponse>;
  getCompletedRequests: (
    token: CancelToken,
    model: IGetRequestsModel
  ) => Promise<IGetRequestsResponse>;
  getCompletedBlockageRequests: (
    token: CancelToken,
    model: IGetCompletedBlockageRequestsModel
  ) => Promise<IGetCompletedBlockageRequestsResponse>;
  getPurchaseReturnRequests: (
    token: CancelToken,
    model: IGetRequestsModel
  ) => Promise<IGetRequestsResponse>;
  getPurchaseReturnRequestsToDownload: (
    token: CancelToken,
    model: IGetPurchaseReturnRequestsToDownloadModel
  ) => Promise<IGetRequestsResponse>;
  getRequestInfoForUpdate: (
    token: CancelToken,
    model: IGetRequestInfoForUpdateModel
  ) => Promise<IGetRequestInfoForUpdateResponse>;
  revertCompletedRequest: (token: CancelToken, model: IRevertCompletedRequest) => Promise<boolean>;
  getReconciliationRequests: (
    token: CancelToken,
    model: IGetReconciliationRequestsModel
  ) => Promise<IGetReconciliationRequestsResponse>;
  getReconciliationRequestDetails: (
    token: CancelToken,
    model: IGetReconciliationRequestDetailsModel
  ) => Promise<Array<IGetReconciliationRequestDetailResponseModel>>;
  addRequest: (token: CancelToken, model: IAddRequestsModel) => Promise<boolean>;
  updateRequest: (token: CancelToken, model: IUpdateRequestModel) => Promise<boolean>;
  cancelRequests: (token: CancelToken, model: ICancelRequestsModel) => Promise<boolean>;

  approveRequests: (token: CancelToken, model: IApproveRequestsModel) => Promise<boolean>;
  bulkAddAndApproveRequests: (token: CancelToken, model: Array<IBulkAddAndApproveRequestModel>) => Promise<IBulkAddAndApproveRequestResponse>;
  approvePurchaseReturnRequests: (
    token: CancelToken,
    model: IApprovePurchaseReturnRequestsModel
  ) => Promise<boolean>;
  approveReconciliationRequests: (
    token: CancelToken,
    model: IApproveReconciliationRequestsModel
  ) => Promise<boolean>;

  // RequestReasons
  getRequestReasons: (token: CancelToken) => Promise<Array<IGetRequestReasonsResponse>>;
  getRequestReasonFilters: (token: CancelToken) => Promise<Array<IGetRequestReasonsResponse>>;
  addRequestReason: (token: CancelToken, model: IAddRequestReasonModel) => Promise<number>;
  updateRequestReason: (token: CancelToken, model: IUpdateRequestReasonModel) => Promise<boolean>;
  deleteRequestReason: (token: CancelToken, model: IDeleteRequestReasonModel) => Promise<boolean>;

  // Request Approval Rules
  getRequestApprovalRules: (
    token: CancelToken,
    model: IGetRequestApprovalRulesModel
  ) => Promise<IGetRequestApprovalRulesResponse>;
  addRequestApprovalRule: (
    token: CancelToken,
    model: IAddRequestApprovalRuleModel
  ) => Promise<number>;
  deleteRequestApprovalRule: (
    token: CancelToken,
    model: IDeleteRequestApprovalRuleModel
  ) => Promise<boolean>;

  // Products
  searchProducts: (
    token: CancelToken,
    model: ISearchProductsModel
  ) => Promise<Array<ISearchProductsResponse>>;

  // Suppliers
  getAllSuppliers: (token: CancelToken) => Promise<Array<IGetAllSuppliersResponse>>;
  getSupplierOrderCollection: (
    token: CancelToken,
    model: IGetSupplierOrderCollectionModel
  ) => Promise<IGetSupplierOrderCollectionResponse>;
  getSupplierOrderProductByCode: (
    token: CancelToken,
    model: IGetSupplierOrderProductByCodeModel
  ) => Promise<IGetSupplierOrderProductByCodeResponse>;
  saveSupplierOrderCollection: (
    token: CancelToken,
    model: ISaveSupplierOrderCollectionModel
  ) => Promise<boolean>;
  checkSupplierOrderCollections: (
    token: CancelToken,
    model: ICheckSupplierOrderCollectionsModel
  ) => Promise<ICheckSupplierOrderCollectionsResponse>;
  getCompletedSupplierOrderCollection: (
    token: CancelToken,
    model: IGetCompletedSupplierOrderCollectionModel
  ) => Promise<IGetCompletedSupplierOrderCollectionResponse>;

  // Request Slips
  getRequestSlips: (
    token: CancelToken,
    model: IGetRequestSlipsModel
  ) => Promise<IGetRequestSlipsResponse>;
  getRequestSlip: (
    token: CancelToken,
    model: IGetRequestSlipModel
  ) => Promise<IGetRequestSlipResponse>;

  // RequestDispatches
  getRequestDispatches: (
    token: CancelToken,
    model: IGetRequestDispatchesModel
  ) => Promise<IGetRequestDispatchesResponse>;
  getRequestDispatch: (
    token: CancelToken,
    model: IGetRequestDispatchModel
  ) => Promise<IGetRequestDispatchResponse>;
  getRequestDispatchesToDownload: (
    token: CancelToken,
    model: IGetRequestDispatchesModel
  ) => Promise<IGetRequestDispatchesResponse>;

  // Dispatches
  getStoreTransferDispatches: (
    token: CancelToken,
    model: IGetStoreTransferDispatchesModel
  ) => Promise<IGetStoreTransferDispatchesResponse>;
  getStoreTransferDispatch: (
    token: CancelToken,
    model: IGetStoreTransferDispatchModel
  ) => Promise<IGetStoreTransferDispatchResponse>;

  updateSupplierOrderCollection: (
    token: CancelToken,
    model: IUpdateSupplierCollectionModel
  ) => Promise<boolean>;
  updateSupplierOrderCollectionProduct: (
    token: CancelToken,
    model: IUpdateSupplierCollectionProductModel
  ) => Promise<boolean>;
  addSupplierOrderCollectionProduct: (
    token: CancelToken,
    model: IUpdateSupplierCollectionProductModel
  ) => Promise<boolean>;

  updateOrderCorrectionPayment: (
    token: CancelToken,
    model: IUpdateOrderCorrectionPaymentModel
  ) => Promise<boolean>;

  //Cash Count Reports
  addCashCountReport: (
    token: CancelToken,
    model: IAddCashCountReportModel
  ) => Promise<IAddCashCountReportResponse>;
  getCashCountReport: (
    token: CancelToken,
    model: IGetCashCountReportModel
  ) => Promise<IGetCashCountReportResponse>;
  getCurrencyUnits: (token: CancelToken) => Promise<Array<IGetCurrencyUnit>>;
  addCurrencyUnitQuantity: (
    token: CancelToken,
    model: IAddCurrencyUnitQuantityModel
  ) => Promise<IGetCashCountReportResponse>;
  deleteCurrencyUnitQuantity: (
    token: CancelToken,
    model: IDeleteCurrencyUnitQuantityModel
  ) => Promise<IGetCashCountReportResponse>;
  updateCashCountReport: (
    token: CancelToken,
    model: IUpdateCashCountReportModel
  ) => Promise<IGetCashCountReportResponse>;
  getCashCountReports: (
    token: CancelToken,
    model: IGetCashCountReportsModel
  ) => Promise<IGetCashCountReportsResponse>;
  sendCashCountReportForApproval: (
    token: CancelToken,
    model: ISendCashCountReportForApprovalModel
  ) => Promise<boolean>;
  approveCashCountReport: (
    token: CancelToken,
    model: IApproveCashCountReportModel
  ) => Promise<boolean>;
  declineCashCountReport: (
    token: CancelToken,
    model: IDeclineCashCountReportModel
  ) => Promise<boolean>;
  getStoreCashCountReportConfigs: (
    token: CancelToken,
    model: IGetStoreCashCountReportConfigsModel
  ) => Promise<IGetStoreCashCountReportConfigsResponse>;
  addStoreCashCountReportConfig: (
    token: CancelToken,
    model: IAddStoreCashCountReportConfigModel
  ) => Promise<boolean>;
  updateStoreCashCountReportConfig: (
    token: CancelToken,
    model: IUpdateStoreCashCountReportConfigModel
  ) => Promise<boolean>;
  getStoreExpirationChecks: (
    token: CancelToken,
    model: IGetStoreExpirationChecksModel
  ) => Promise<IGetStoreExpirationChecksResponse>;
  getStoreExpirationCheckWithProducts: (
    token: CancelToken,
    model: IGetStoreExpirationCheckWithProductsModel
  ) => Promise<IGetStoreExpirationCheckWithProductsResponse>;
  addStoreExpirationCheck: (
    token: CancelToken,
    model: IAddStoreExpirationCheckModel
  ) => Promise<boolean>;
  getStoreExpirationCheckProductDetail: (
    token: CancelToken,
    model: IGetStoreExpirationCheckProductDetailModel
  ) => Promise<Array<IGetStoreExpirationCheckProductDetailResponse>>;
  updateStoreExpirationCheckProduct: (
    token: CancelToken,
    model: IUpdateStoreExpirationCheckProductModel
  ) => Promise<boolean>;
  updateStoreExpirationCheckStatus: (
    token: CancelToken,
    model: IUpdateStoreExpirationCheckStatusModel
  ) => Promise<boolean>;
  getStoreExpirationCheckReport: (
    token: CancelToken,
    model: IGetStoreExpirationCheckReportModel
  ) => Promise<Array<IGetStoreExpirationCheckReportResponse>>;
  getStoreProductProductionQuantityReport: (
    token: CancelToken,
    model: IGetStoreProductProductionQuantityReportModel
  ) => Promise<IGetStoreProductProductionQuantityReportResponse>;
  getStoreProductProductionQuantityReportDownload: (
    token: CancelToken,
    model: IGetStoreProductProductionQuantityReportDownloadModel
  ) => Promise<Array<IGetStoreProductProductionQuantityReportDownloadResponse>>;
}

const AdminServiceContext = createContext<IAdminServiceContext>({} as IAdminServiceContext);

interface IProps {
  children: React.ReactElement;
}

export function AdminService(props: IProps) {
  const environmentGlobal = useEnvironmentGlobal();

  const axiosService = useAxiosService(
    axios.create({
      baseURL: environmentGlobal.adminApiUrl,
      timeout: 30000,
      responseType: "json",
      headers: { "Cache-Control": "no-cache,no-store" },
      paramsSerializer: (params) => {
        return queryString.stringify(params, { arrayFormat: "none" });
      }
    })
  );

  // Sample
  function getSample(token: CancelToken): Promise<IGetSampleResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "/sample",
      cancelToken: token
    };

    return axiosService.send<IGetSampleResponse>(config);
  }

  // Users
  function getPermissions(token: CancelToken): Promise<Array<string>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "/users/permissions",
      cancelToken: token
    };

    return axiosService.send<Array<string>>(config);
  }

  function getUsers(token: CancelToken, model: IGetUsersModel): Promise<IGetUsersResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "/users",
      cancelToken: token,
      params: {
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SearchQuery: model.SearchQuery,
        SortDirection: model.SortDirection,
        SortField: model.SortField
      }
    };

    return axiosService.send<IGetUsersResponse>(config);
  }

  function getMyInfo(token: CancelToken): Promise<IGetMyInfoResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "/users/info",
      cancelToken: token
    };

    return axiosService.send<IGetMyInfoResponse>(config);
  }

  function getMyStores(token: CancelToken): Promise<Array<IGetUserStoresResponse>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "/users/stores",
      cancelToken: token
    };

    return axiosService.send<Array<IGetUserStoresResponse>>(config);
  }

  function getUserStores(
    token: CancelToken,
    model: IGetUserStoresModel
  ): Promise<Array<IGetUserStoresResponse>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/users/${model.UserId}/stores`,
      cancelToken: token
    };

    return axiosService.send<Array<IGetUserStoresResponse>>(config);
  }

  function getUser(token: CancelToken, model: IGetUserModel): Promise<IGetUserResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/users/${model.UserName}`,
      cancelToken: token
    };

    return axiosService.send<IGetUserResponse>(config);
  }

  function addNewUser(token: CancelToken, model: IAddNewUserModel): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/users`,
      data: {
        UserName: model.UserName,
        FirstName: model.FirstName,
        LastName: model.LastName,
        Email: model.Email,
        Password: model.Password,
        IsActive: model.IsActive,
        IdentityCardNumber: model.IdentityCardNumber
      },
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function updateUser(token: CancelToken, model: IUpdateUserModel): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "put",
      url: `/users/${model.UserId}`,
      data: {
        FirstName: model.FirstName,
        LastName: model.LastName,
        UserName: model.UserName,
        Email: model.Email,
        Password: model.Password,
        IsActive: model.IsActive,
        IdentityCardNumber: model.IdentityCardNumber
      },
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function deleteUser(token: CancelToken, model: IDeleteUserModel): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "delete",
      url: `/users/${model.UserId}`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function getAllStoresWithUserAssignments(
    token: CancelToken,
    model: IGetAllStoresWithUserAssignmentsModel
  ): Promise<Array<IGetAllStoresWithUserAssignmentsResponse>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/users/${model.UserId}/stores/all`,
      cancelToken: token
    };

    return axiosService.send<Array<IGetAllStoresWithUserAssignmentsResponse>>(config);
  }

  function saveUserStores(token: CancelToken, model: ISaveUserStoresModel): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `users/${model.UserId}/stores`,
      cancelToken: token,
      data: {
        UserId: model.UserId,
        StoreIds: model.StoreIds
      }
    };

    return axiosService.send<boolean>(config);
  }

  function getAllPermissionsWithUserAssignments(
    token: CancelToken,
    model: IGetAllPermissionsWithUserAssignmentsModel
  ): Promise<IGetAllPermissionsWithUserAssignmentsResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/users/${model.UserId}/permissions`,
      cancelToken: token
    };

    return axiosService.send<IGetAllPermissionsWithUserAssignmentsResponse>(config);
  }

  function saveUserPermissions(
    token: CancelToken,
    model: ISaveUserPermissionsModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `users/${model.UserId}/permissions`,
      cancelToken: token,
      data: {
        UserId: model.UserId,
        PermissionIds: model.PermissionIds
      }
    };
    return axiosService.send<boolean>(config);
  }

  // Stores
  function getStoreProducts(
    token: CancelToken,
    model: IGetStoreProductsModel
  ): Promise<IGetStoreProductsResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/products`,
      cancelToken: token,
      data: {
        StoreIds: model.StoreIds,
        WarehouseTypeIds: model.WarehouseTypeIds
      },
      params: {
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SearchQuery: model.SearchQuery,
        SortField: model.SortField,
        SortDirection: model.SortDirection
      }
    };

    return axiosService.send<IGetStoreProductsResponse>(config);
  }

  function getStoreProductsToDownload(
    token: CancelToken,
    model: IGetStoreProductsToDownloadModel
  ): Promise<Array<IGetStoreProductsToDownloadResponse>> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/products/download`,
      cancelToken: token,
      data: {
        StoreIds: model.StoreIds,
        WarehouseTypeIds: model.WarehouseTypeIds
      },
      params: {
        SearchQuery: model.SearchQuery
      }
    };

    return axiosService.send<Array<IGetStoreProductsToDownloadResponse>>(config);
  }

  function getStoreProductExpirationDateReport(
    token: CancelToken,
    model: IGetStoreProductExpirationDateReportModel
  ): Promise<IGetStoreProductExpirationDateReportResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/${model.StoreId}/warehouse-type/${model.WarehouseTypeId}/expiration-date-report`,
      cancelToken: token,
      params: {
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SearchQuery: model.SearchQuery,
        SortField: model.SortField,
        SortDirection: model.SortDirection
      }
    };

    return axiosService.send<IGetStoreProductExpirationDateReportResponse>(config);
  }

  function getStoreProductExpirationDateReportToDownload(
    token: CancelToken,
    model: IGetStoreProductExpirationDateReportToDownloadModel
  ): Promise<Array<IGetStoreProductExpirationDateReportToDownloadResponse>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/${model.StoreId}/warehouse-type/${model.WarehouseTypeId}/expiration-date-report/download`,
      cancelToken: token,
      params: {
        SearchQuery: model.SearchQuery
      }
    };

    return axiosService.send<Array<IGetStoreProductExpirationDateReportToDownloadResponse>>(config);
  }

  function getStorePaymentReport(
    token: CancelToken,
    model: IGetStorePaymentReportModel
  ): Promise<IGetStorePaymentReportResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/payment-report`,
      cancelToken: token,
      data: {
        StoreIds: model.StoreIds,
        ReportDate: model.ReportDate,
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SearchQuery: model.SearchQuery,
        SortField: model.SortField,
        SortDirection: model.SortDirection
      }
    };

    return axiosService.send<IGetStorePaymentReportResponse>(config);
  }

  function getStorePaymentReportToDownload(
    token: CancelToken,
    model: IGetStorePaymentReportToDownloadModel
  ): Promise<Array<IGetStorePaymentReportToDownloadResponse>> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/payment-report/download`,
      cancelToken: token,
      data: {
        StoreIds: model.StoreIds,
        ReportDate: model.ReportDate,
        SearchQuery: model.SearchQuery
      }
    };

    return axiosService.send<Array<IGetStorePaymentReportToDownloadResponse>>(config);
  }

  function getStoreDetailedPaymentReport(
    token: CancelToken,
    model: IGetStoreDetailedPaymentReportModel
  ): Promise<IGetStoreDetailedPaymentReportResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/detailed-payment-report`,
      cancelToken: token,
      data: {
        StoreIds: model.StoreIds,
        ReportDate: model.ReportDate,
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SearchQuery: model.SearchQuery,
        SortField: model.SortField,
        SortDirection: model.SortDirection
      }
    };

    return axiosService.send<IGetStoreDetailedPaymentReportResponse>(config);
  }

  function getVerifoneTransactionLogsReportToDownload(
    token: CancelToken,
    model: IGetVerifoneTransactionLogsReportToDownloadModel
  ): Promise<Array<IGetVerifoneTransactionLogsReportResponseModel>> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/verifone/verifone-report/download`,
      cancelToken: token,
      data: {
        TrackingNumber: model.TrackingNumber,
        ReportStartDate: model.ReportStartDate,
        ReportFinishDate: model.ReportFinishDate,
        SearchQuery: model.SearchQuery,
        SearchWithDates: model.SearchWithDates,
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SortField: model.SortField,
        SortDirection: model.SortDirection
      }
    };

    return axiosService.send<Array<IGetVerifoneTransactionLogsReportResponseModel>>(config);
  }

  function getVerifoneTransactionLogsReport(
    token: CancelToken,
    model: IGetVerifoneTransactionLogsReportModel
  ): Promise<IGetVerifoneTransactionLogsReportResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/verifone/verifone-report`,
      cancelToken: token,
      data: {
        TrackingNumber: model.TrackingNumber,
        ReportStartDate: model.ReportStartDate,
        ReportFinishDate: model.ReportFinishDate,
        SearchWithDates: model.SearchWithDates,
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SortField: model.SortField,
        SortDirection: model.SortDirection,
        SearchQuery: model.SearchQuery
      }
    };

    return axiosService.send<IGetVerifoneTransactionLogsReportResponse>(config);
  }

  function getStoreDetailedPaymentReportToDownload(
    token: CancelToken,
    model: IGetStoreDetailedPaymentReportToDownloadModel
  ): Promise<Array<IGetStoreDetailedPaymentReportToDownloadResponse>> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/detailed-payment-report/download`,
      cancelToken: token,
      data: {
        StoreIds: model.StoreIds,
        ReportDate: model.ReportDate,
        SearchQuery: model.SearchQuery
      }
    };

    return axiosService.send<Array<IGetStoreDetailedPaymentReportToDownloadResponse>>(config);
  }

  function getDetailedApprovedByPaymentReportToDownload(
    token: CancelToken,
    model: IGetDetailedApprovedbyReportDownloadModel
  ): Promise<Array<IGetStoreApprovedByDetailedPaymentResportToDownloadResponse>> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/detailed-payment-approved-by-report/download`,
      cancelToken: token,
      data: {
        StoreId: model.StoreId,
        ReportDate: model.ReportDate,
        ApprovedBy: model.ApprovedBy,
        BankCode: model.BankCode,
        PaymentMethodTypeId: model.PaymentMethodTypeId
      }
    };

    return axiosService.send<Array<IGetStoreApprovedByDetailedPaymentResportToDownloadResponse>>(
      config
    );
  }

  function getStoreProduct(
    token: CancelToken,
    model: IGetStoreProductModel
  ): Promise<IGetStoreProductResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/${model.StoreId}/warehouse-type/${model.WarehouseTypeId}/products/${model.ProductId}`,
      cancelToken: token
    };

    return axiosService.send<IGetStoreProductResponse>(config);
  }

  function getWarehouseProduct(
    token: CancelToken,
    model: IGetWarehouseProductModel
  ): Promise<IGetWarehouseProductResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/${model.StoreId}/warehouse-type/${model.WarehouseTypeId}/products/code/${model.BarcodeNumberOrMaterialCode}`,
      cancelToken: token
    };

    return axiosService.send<IGetWarehouseProductResponse>(config);
  }

  function getStoreProductSuppliers(
    token: CancelToken,
    model: IGetStoreProductSuppliersModel
  ): Promise<Array<IGetStoreProductSuppliersResponse>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/${model.StoreId}/product-suppliers/${model.BarcodeNumberOrMaterialCode}`,
      cancelToken: token
    };

    return axiosService.send<Array<IGetStoreProductSuppliersResponse>>(config);
  }

  function getStoreActiveOrders(
    token: CancelToken,
    model: IGetStoreActiveOrdersModel
  ): Promise<IGetStoreActiveOrdersResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/${model.StoreId}/active-orders`,
      cancelToken: token,
      params: {
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SearchQuery: model.SearchQuery
      }
    };

    return axiosService.send<IGetStoreActiveOrdersResponse>(config);
  }

  function getStoreInventories(
    token: CancelToken,
    model: IGetStoreInventoriesModel
  ): Promise<IGetStoreInventoriesResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/${model.StoreId}/inventories`,
      cancelToken: token,
      params: {
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SearchQuery: model.SearchQuery
      }
    };

    return axiosService.send<IGetStoreInventoriesResponse>(config);
  }

  function addStoreInventory(token: CancelToken, model: IAddStoreInventoryModel): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "/stores/inventories",
      cancelToken: token,
      data: {
        StoreId: model.StoreId,
        InventoryName: model.InventoryName,
        ShelfBarcodes: model.ShelfBarcodes
      }
    };

    return axiosService.send<boolean>(config);
  }

  function getStoreInventoryCollections(
    token: CancelToken,
    model: IGetStoreInventoryCollectionsModel
  ): Promise<Array<IGetStoreInventoryCollectionsResponse>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/inventories/${model.InventoryId}/collections`,
      cancelToken: token
    };

    return axiosService.send<Array<IGetStoreInventoryCollectionsResponse>>(config);
  }

  function getStoreInventoryCollection(
    token: CancelToken,
    model: IGetStoreInventoryCollectionModel
  ): Promise<IGetStoreInventoryCollectionResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/inventories/${model.InventoryId}/collections/${model.CollectionId}`,
      cancelToken: token
    };

    return axiosService.send<IGetStoreInventoryCollectionResponse>(config);
  }

  function getStoreInventory(
    token: CancelToken,
    model: IGetStoreInventoryModel
  ): Promise<IGetStoreInventoryResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/inventories/${model.InventoryId}`,
      cancelToken: token
    };

    return axiosService.send<IGetStoreInventoryResponse>(config);
  }

  function getStoreInventoryPreview(
    token: CancelToken,
    model: IGetStoreInventoryPreviewModel
  ): Promise<IGetStoreInventoryPreviewResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/inventories/${model.InventoryId}/preview`,
      cancelToken: token,
      params: {
        PageSize: model.PageSize,
        PageNumber: model.PageNumber,
        SearchQuery: model.SearchQuery,
        SortField: model.SortField,
        SortDirection: model.SortDirection
      }
    };

    return axiosService.send<IGetStoreInventoryPreviewResponse>(config);
  }

  function getStoreInventoryPreviewToDownload(
    token: CancelToken,
    model: IGetStoreInventoryPreviewToDownloadModel
  ): Promise<Array<IGetStoreInventoryPreviewToDownloadResponse>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/inventories/${model.InventoryId}/preview/download`,
      cancelToken: token,
      params: {
        SearchQuery: model.SearchQuery
      }
    };

    return axiosService.send<Array<IGetStoreInventoryPreviewToDownloadResponse>>(config);
  }

  function getStoreCompletedInventoryToDownload(
    token: CancelToken,
    model: IGetStoreCompletedInventoryToDownloadModel
  ): Promise<Array<IGetStoreCompletedInventoryToDownloadResponse>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/inventories/${model.InventoryId}/completed/download`,
      cancelToken: token
    };

    return axiosService.send<Array<IGetStoreCompletedInventoryToDownloadResponse>>(config);
  }

  function addStoreInventoryProcessedStocks(
    token: CancelToken,
    model: IAddStoreInventoryProcessedStocksModel
  ): Promise<string | null> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/${model.StoreId}/inventories/${model.InventoryId}/processed-stocks`,
      cancelToken: token
    };

    return axiosService.send<string | null>(config);
  }

  function getStoreStockDifferenceToDownload(
    token: CancelToken,
    model: IGetStoreStockDifferenceToDownloadModel
  ): Promise<Array<IGetStoreStockDifferenceToDownloadResponse>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/${model.StoreId}/stock-difference/${model.ProcessId}/download`,
      cancelToken: token
    };

    return axiosService.send<Array<IGetStoreStockDifferenceToDownloadResponse>>(config);
  }

  function startStoreInventoryCollection(
    token: CancelToken,
    model: IStartStoreInventoryCollectionModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/inventories/${model.InventoryId}/collections/${model.CollectionId}/start`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function stopStoreInventoryCollection(
    token: CancelToken,
    model: IStopStoreInventoryCollectionModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/inventories/${model.InventoryId}/collections/${model.CollectionId}/stop`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function completeStoreInventoryCollection(
    token: CancelToken,
    model: ICompleteStoreInventoryCollectionModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/inventories/${model.InventoryId}/collections/${model.CollectionId}/complete`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function cancelStoreInventoryCollection(
    token: CancelToken,
    model: ICancelStoreInventoryCollectionModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/inventories/${model.InventoryId}/collections/${model.CollectionId}/cancel`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function getStoreAllInventoryCollectionProducts(
    token: CancelToken,
    model: IGetStoreAllInventoryCollectionProductsModel
  ): Promise<Array<IGetStoreInventoryCollectionProductsResponse>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/inventories/${model.InventoryId}/collections/products`,
      cancelToken: token
    };

    return axiosService.send<Array<IGetStoreInventoryCollectionProductsResponse>>(config);
  }

  function getStoreInventoryCollectionProducts(
    token: CancelToken,
    model: IGetStoreInventoryCollectionProductsModel
  ): Promise<Array<IGetStoreInventoryCollectionProductsResponse>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/inventories/${model.InventoryId}/collections/${model.CollectionId}/products`,
      cancelToken: token
    };

    return axiosService.send<Array<IGetStoreInventoryCollectionProductsResponse>>(config);
  }

  function deleteStoreInventoryCollectionProducts(
    token: CancelToken,
    model: IDeleteStoreInventoryCollectionProductsModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "delete",
      url: `/stores/inventories/${model.InventoryId}/collections/${model.CollectionId}/products`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function addOrUpdateStoreInventoryCollectionProduct(
    token: CancelToken,
    model: IAddOrUpdateStoreInventoryCollectionProductModel
  ): Promise<IAddOrUpdateStoreInventoryCollectionProductResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/inventories/${model.InventoryId}/collections/${model.CollectionId}/products`,
      cancelToken: token,
      data: {
        BarcodeNumberOrMaterialCode: model.BarcodeNumberOrMaterialCode,
        Quantity: model.Quantity
      }
    };

    return axiosService.send<IAddOrUpdateStoreInventoryCollectionProductResponse>(config);
  }

  function updateStoreInventoryCollectionProduct(
    token: CancelToken,
    model: IUpdateStoreInventoryCollectionProductModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "patch",
      url: `/stores/inventories/${model.InventoryId}/collections/${model.CollectionId}/products/${model.ProductId}`,
      cancelToken: token,
      data: {
        Quantity: model.Quantity
      }
    };

    return axiosService.send<boolean>(config);
  }

  function updateStoreInventoryStatus(
    token: CancelToken,
    model: IUpdateStoreInventoryStatusModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "patch",
      url: `/stores/inventories/${model.InventoryId}/status`,
      cancelToken: token,
      data: {
        Status: model.Status
      }
    };

    return axiosService.send<boolean>(config);
  }

  function completeStoreInventory(
    token: CancelToken,
    model: ICompleteStoreInventoryModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "patch",
      url: `/stores/inventories/${model.InventoryId}/complete`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function cancelStoreInventory(
    token: CancelToken,
    model: ICancelStoreInventoryModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "patch",
      url: `/stores/inventories/${model.InventoryId}/cancel`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function approveStoreInventory(
    token: CancelToken,
    model: IApproveStoreInventoryModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "patch",
      url: `/stores/inventories/${model.InventoryId}/approve`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function deleteStoreInventoryCollectionProduct(
    token: CancelToken,
    model: IDeleteStoreInventoryCollectionProductModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "delete",
      url: `/stores/inventories/${model.InventoryId}/collections/${model.CollectionId}/products/${model.ProductId}`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function getStoreReturnedOrders(
    token: CancelToken,
    model: IGetStoreReturnedOrdersModel
  ): Promise<IGetStoreReturnedOrdersResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/${model.StoreId}/returned-orders`,
      cancelToken: token,
      params: {
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SearchQuery: model.SearchQuery
      }
    };

    return axiosService.send<IGetStoreReturnedOrdersResponse>(config);
  }

  function getStoreOfflineOrders(
    token: CancelToken,
    model: IGetStoreOfflineOrdersModel
  ): Promise<IGetStoreOfflineOrdersResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/${model.StoreId}/offline-orders`,
      cancelToken: token,
      params: {
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SearchQuery: model.SearchQuery,
        PaymentMethodTypeName: model.PaymentMethodTypeName
      }
    };

    return axiosService.send<IGetStoreOfflineOrdersResponse>(config);
  }

  function addWarehouseTransfer(
    token: CancelToken,
    model: IAddWarehouseTransferModel
  ): Promise<IGetWarehouseTransferResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/${model.StoreId}/warehouses/transfers`,
      cancelToken: token,
      data: {
        SourceWarehouseTypeId: model.SourceWarehouseTypeId,
        TargetWarehouseTypeId: model.TargetWarehouseTypeId
      }
    };

    return axiosService.send<IGetWarehouseTransferResponse>(config);
  }

  function addWarehouseTransferProduct(
    token: CancelToken,
    model: IAddWarehouseTransferProductModel
  ): Promise<IAddWarehouseTransferProductResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/${model.StoreId}/warehouses/transfers/${model.TransferId}/products`,
      cancelToken: token,
      data: {
        BarcodeNumberOrMaterialCode: model.BarcodeNumberOrMaterialCode,
        Quantity: model.Quantity,
        ProductModel:
          model.ProductId === null || model.ExpirationDate === null
            ? null
            : {
                ProductId: model.ProductId,
                ExpirationDate: model.ExpirationDate
              }
      }
    };

    return axiosService.send<IAddWarehouseTransferProductResponse>(config);
  }

  function deleteWarehouseTransfer(
    token: CancelToken,
    model: IDeleteWarehouseTransferModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "delete",
      url: `/stores/${model.StoreId}/warehouses/transfers/${model.TransferId}`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function deleteWarehouseTransferProduct(
    token: CancelToken,
    model: IDeleteWarehouseTransferProductModel
  ): Promise<IGetWarehouseTransferResponse> {
    const config: AxiosRequestConfig = {
      method: "delete",
      url: `/stores/${model.StoreId}/warehouses/transfers/${model.TransferId}/products`,
      cancelToken: token,
      data: {
        ProductId: model.ProductId,
        ExpirationDate: model.ExpirationDate
      }
    };

    return axiosService.send<IGetWarehouseTransferResponse>(config);
  }

  function saveWarehouseTransfer(
    token: CancelToken,
    model: ISaveWarehouseTransferModel
  ): Promise<ISaveWarehouseTransferResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/${model.StoreId}/warehouses/transfers/${model.TransferId}/save`,
      cancelToken: token
    };

    return axiosService.send<ISaveWarehouseTransferResponse>(config);
  }

  function getStoreSupplierOrders(
    token: CancelToken,
    model: IGetStoreSupplierOrdersModel
  ): Promise<IGetStoreSupplierOrdersResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/${model.StoreId}/suppliers/orders`,
      cancelToken: token,
      params: {
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SearchQuery: model.SearchQuery
      }
    };

    return axiosService.send<IGetStoreSupplierOrdersResponse>(config);
  }

  function getStoreTransfers(
    token: CancelToken,
    model: IGetStoreTransfersFilterModel
  ): Promise<IGetStoreTransfersResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "/stores/transfers",
      cancelToken: token,
      paramsSerializer: function (params) {
        return qs.stringify(params, { allowDots: true, encode: false });
      },
      params: model
    };

    return axiosService.send<IGetStoreTransfersResponse>(config);
  }

  function getStoreCompletedTransfers(
    token: CancelToken,
    model: IGetStoreCompletedTransfersFilterModel
  ): Promise<IGetStoreTransfersResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "/stores/transfers/completed",
      cancelToken: token,
      paramsSerializer: function (params) {
        return qs.stringify(params, { allowDots: true, encode: false });
      },
      params: model
    };

    return axiosService.send<IGetStoreTransfersResponse>(config);
  }

  function getStoreCompletedTransfer(
    token: CancelToken,
    model: IGetStoreCompletedTransferModel
  ): Promise<IGetStoreCompletedTransferResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/transfers/${model.TransferId}/completed`,
      cancelToken: token
    };

    return axiosService.send<IGetStoreCompletedTransferResponse>(config);
  }



  function addStoreTransfer(
    token: CancelToken,
    model: IAddStoreTransferModel
  ): Promise<IGetStoreTransferResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "/stores/transfers",
      cancelToken: token,
      data: {
        SourceStoreId: model.SourceStoreId,
        TargetStoreId: model.TargetStoreId,
        SourceWarehouseTypeId: model.SourceWarehouseTypeId,
        TargetWarehouseTypeId: model.TargetWarehouseTypeId,
        ReasonType: model.ReasonType,
        IsSourceStoreCentral: model.IsSourceStoreCentral,
        TransferDescriptionId: model.TransferDescriptionId
      }
    };

    return axiosService.send<IGetStoreTransferResponse>(config);
  }

  function getStoreTransfer(
    token: CancelToken,
    model: IGetStoreTransferModel
  ): Promise<IGetStoreTransferResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/transfers/${model.TransferId}`,
      cancelToken: token
    };

    return axiosService.send<IGetStoreTransferResponse>(config);
  }

  function addStoreTransferProduct(
    token: CancelToken,
    model: IAddStoreTransferProductModel
  ): Promise<IGetStoreTransferResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/transfers/${model.TransferId}/product`,
      cancelToken: token,
      data: {
        Code: model.Code,
        Quantity: model.Quantity
      }
    };

    return axiosService.send<IGetStoreTransferResponse>(config);
  }

  function addStoreTransferProducts(
    token: CancelToken,
    model: IAddStoreTransferProductsModel
  ): Promise<IGetStoreTransferResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/transfers/${model.TransferId}/products`,
      cancelToken: token,
      data: {
        TransferProducts: model.TransferProducts
      }
    };

    return axiosService.send<IGetStoreTransferResponse>(config);
  }

  function deleteStoreTransferProduct(
    token: CancelToken,
    model: IDeleteStoreTransferProductModel
  ): Promise<IGetStoreTransferResponse> {
    const config: AxiosRequestConfig = {
      method: "delete",
      url: `/stores/transfers/${model.TransferId}/products/${model.ProductId}`,
      cancelToken: token
    };

    return axiosService.send<IGetStoreTransferResponse>(config);
  }

  function deleteStoreTransferProducts(
    token: CancelToken,
    model: IDeleteStoreTransferProductsModel
  ): Promise<IGetStoreTransferResponse> {
    const config: AxiosRequestConfig = {
      method: "delete",
      url: `/stores/transfers/${model.TransferId}/products`,
      cancelToken: token
    };

    return axiosService.send<IGetStoreTransferResponse>(config);
  }

  function saveStoreTransfer(token: CancelToken, model: ISaveStoreTransferModel): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/transfers/${model.TransferId}/save`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function bulkSaveStoreTransfer(
    token: CancelToken,
    model: IBulkSaveStoreTransferModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/transfers/bulk/save`,
      cancelToken: token,
      data: model.TransferIds
    };

    return axiosService.send<boolean>(config);
  }

  function partiallySaveStoreTransfer(token: CancelToken, model: ISaveStoreTransferModel): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/transfers/${model.TransferId}/partially-save`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function bulkPartiallySaveStoreTransfer(
    token: CancelToken,
    model: IBulkSaveStoreTransferModel
  ): Promise<IBulkPartiallySaveStoreTransferResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/transfers/bulk/partially-save`,
      cancelToken: token,
      data: model.TransferIds
    };

    return axiosService.send<IBulkPartiallySaveStoreTransferResponse>(config);
  }

  function cancelStoreTransfers(
    token: CancelToken,
    model: ICancelStoreTransfersModel
  ): Promise<ICancelStoreTransfersServiceResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "/stores/transfers/cancel",
      cancelToken: token,
      data: model
    };

    return axiosService.send<ICancelStoreTransfersServiceResponse>(config);
  }

  function updateStoreTransfer(
    token: CancelToken,
    model: IUpdateStoreTransferModel
  ): Promise<IGetStoreTransferResponse> {
    const config: AxiosRequestConfig = {
      method: "put",
      url: `/stores/transfers/${model.TransferId}`,
      cancelToken: token,
      data: {
        SourceStoreId: model.SourceStoreId,
        TargetStoreId: model.TargetStoreId,
        SourceWarehouseTypeId: model.SourceWarehouseTypeId,
        TargetWarehouseTypeId: model.TargetWarehouseTypeId,
        IsSourceStoreCentral: model.IsSourceStoreCentral,
        TransferDescriptionId: model.TransferDescriptionId
      }
    };

    return axiosService.send<IGetStoreTransferResponse>(config);
  }

  function updateStoreTransferProduct(
    token: CancelToken,
    model: IUpdateStoreTransferProductModel
  ): Promise<IGetStoreTransferResponse> {
    const config: AxiosRequestConfig = {
      method: "put",
      url: `/stores/transfers/${model.TransferId}/products/${model.ProductId}`,
      cancelToken: token,
      data: {
        Quantity: model.Quantity
      }
    };

    return axiosService.send<IGetStoreTransferResponse>(config);
  }

  function getStoreOutgoingTransfers(
    token: CancelToken,
    model: IGetStoreOutgoingTransfersModel
  ): Promise<IGetStoreTransfersResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/${model.StoreId}/transfers/outgoing`,
      cancelToken: token,
      paramsSerializer: function (params) {
        return qs.stringify(params, { allowDots: true, encode: false });
      },
      params: model
    };

    return axiosService.send<IGetStoreTransfersResponse>(config);
  }

  function getStoreOutgoingTransfer(
    token: CancelToken,
    model: IGetStoreOutgoingTransferModel
  ): Promise<IGetStoreOutgoingTransferResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/transfers/${model.TransferId}/outgoing`,
      cancelToken: token
    };

    return axiosService.send<IGetStoreOutgoingTransferResponse>(config);
  }

  function getStoreTransferProductByCode(
    token: CancelToken,
    model: IGetStoreTransferProductByCodeModel
  ): Promise<IGetStoreTransferProductByCodeResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/transfers/${model.TransferId}/products/${model.Code}?quantity=${model.Quantity}`,
      cancelToken: token
    };

    return axiosService.send<IGetStoreTransferProductByCodeResponse>(config);
  }

  function saveStoreOutgoingTransferCollection(
    token: CancelToken,
    model: ISaveStoreOutgoingTransferCollectionModel
  ): Promise<ISaveStoreOutgoingTransferCollectionResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "/stores/transfers/outgoing/collections/save",
      cancelToken: token,
      data: {
        TransferId: model.TransferId,
        DispatchDate: model.DispatchDate,
        DriverName: model.DriverName,
        DriverPlate: model.DriverPlate,
        DriverIdentityCardNumber: model.DriverIdentityCardNumber,
        CollectedProducts: model.CollectedProducts
      }
    };

    return axiosService.send<ISaveStoreOutgoingTransferCollectionResponse>(config);
  }

  function getStoreIncomingTransfers(
    token: CancelToken,
    model: IGetStoreIncomingTransfersModel
  ): Promise<IGetStoreTransfersResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/${model.StoreId}/transfers/incoming`,
      cancelToken: token,
      paramsSerializer: function (params) {
        return qs.stringify(params, { allowDots: true, encode: false });
      },
      params: model
    };

    return axiosService.send<IGetStoreTransfersResponse>(config);
  }

  function getStoreIncomingTransferCollection(
    token: CancelToken,
    model: IGetStoreIncomingTransferCollectionModel
  ): Promise<IGetStoreIncomingTransferCollectionResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/transfers/collections/${model.CollectionId}`,
      cancelToken: token
    };

    return axiosService.send<IGetStoreIncomingTransferCollectionResponse>(config);
  }

  function getStoreIncomingTransferCollectionIdByPalletId(
    token: CancelToken,
    model: IGetStoreIncomingTransferCollectionIdByPalletIdModel
  ): Promise<number> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/${model.StoreId}/transfers/collections/pallet/${model.PalletId}`,
      cancelToken: token
    };

    return axiosService.send<number>(config);
  }

  function saveStoreIncomingTransferCollection(
    token: CancelToken,
    model: ISaveStoreIncomingTransferCollectionModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/transfers/${model.TransferId}/incoming/collections/${model.CollectionId}/save`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function transferStockBetweenProducts(
    token: CancelToken,
    model: ITransferStockBetweenProductsModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/${model.StoreId}/warehouse-type/${model.WarehouseTypeId}/stock-transfer`,
      data: {
        SourceProductId: model.SourceProductId,
        SourceExpirationDate: model.SourceExpirationDate,
        SourceQuantity: model.SourceQuantity,
        TargetProductId: model.TargetProductId,
        TargetExpirationDate: model.TargetExpirationDate,
        TargetQuantity: model.TargetQuantity
      },
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function getStoreSupplierOrderDispatches(
    token: CancelToken,
    model: IGetStoreSupplierOrderDispatchesModel
  ): Promise<IGetStoreSupplierOrderDispatchesResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/${model.StoreId}/suppliers/orders/dispatches`,
      cancelToken: token,
      paramsSerializer: function (params) {
        return qs.stringify(params, { allowDots: true, encode: false });
      },
      params: {
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SortDirection: model.SortDirection,
        SortField: model.SortField,
        OrderId: model.OrderId,
        DispatchNo: model.DispatchNo,
        SupplierCode: model.SupplierCode,
        DispatchDate: model.DispatchDate
      }
    };

    return axiosService.send<IGetStoreSupplierOrderDispatchesResponse>(config);
  }

  // Orders
  function getOrderCollection(
    token: CancelToken,
    model: IGetOrderCollectionModel
  ): Promise<IGetOrderCollectionResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/orders/${model.OrderId}/collection`,
      cancelToken: token
    };

    return axiosService.send<IGetOrderCollectionResponse>(config);
  }

  function deleteOrderCollection(
    token: CancelToken,
    model: IDeleteOrderCollectionModel
  ): Promise<IGetOrderCollectionResponse> {
    const config: AxiosRequestConfig = {
      method: "delete",
      url: `/orders/${model.OrderId}/collections`,
      cancelToken: token
    };

    return axiosService.send<IGetOrderCollectionResponse>(config);
  }

  function deleteOrderCollectionProduct(
    token: CancelToken,
    model: IDeleteOrderCollectionProductModel
  ): Promise<IGetOrderCollectionResponse> {
    const config: AxiosRequestConfig = {
      method: "delete",
      url: `/orders/${model.OrderId}/collections/products/${model.OrderProductId}`,
      cancelToken: token
    };

    return axiosService.send<IGetOrderCollectionResponse>(config);
  }

  function addOrderCollectionProduct(
    token: CancelToken,
    model: IAddOrderCollectionProductModel
  ): Promise<IGetOrderCollectionResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/orders/${model.OrderId}/collections/products`,
      cancelToken: token,
      data: {
        StoreId: model.StoreId,
        BarcodeNumberOrMaterialCode: model.BarcodeNumberOrMaterialCode,
        Quantity: model.Quantity
      }
    };

    return axiosService.send<IGetOrderCollectionResponse>(config);
  }

  function addOrderCollectionWasteProduct(
    token: CancelToken,
    model: IAddOrderCollectionWasteProductModel
  ): Promise<IGetOrderCollectionResponse> {
    const config: AxiosRequestConfig = {
      method: "patch",
      url: `/orders/${model.OrderId}/collections/products/${model.OrderProductId}/waste`,
      cancelToken: token
    };

    return axiosService.send<IGetOrderCollectionResponse>(config);
  }

  function saveOrderCollection(
    token: CancelToken,
    model: ISaveOrderCollectionModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/orders/${model.OrderId}/collections`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function getOrderRefundCollection(
    token: CancelToken,
    model: IGetOrderRefundCollectionModel
  ): Promise<IGetOrderRefundCollectionResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/orders/${model.OrderId}/refunds/${model.OrderRefundId}/collections`,
      cancelToken: token
    };

    return axiosService.send<IGetOrderRefundCollectionResponse>(config);
  }

  function saveOrderRefund(token: CancelToken, model: ISaveOrderRefundModel): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/orders/${model.OrderId}/refunds/${model.OrderRefundId}/save`,
      data: {
        RefundProducts: model.RefundProducts,
        ForceApprove: model.ForceApprove
      },
      params: {
        ForceApprove: model.ForceApprove
      },
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function startOrderRefund(token: CancelToken, model: IStartOrderRefundModel): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/orders/${model.OrderId}/refunds/${model.OrderRefundId}/start`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function saveOfflineOrderPayment(
    token: CancelToken,
    model: ISaveOfflineOrderPaymentModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/orders/${model.OrderId}/payments/offline`,
      cancelToken: token,
      params: {
        PaymentMethodTypeId: model.PaymentMethodTypeId
      }
    };

    return axiosService.send<boolean>(config);
  }

  // Pos Users
  function getPosUsers(
    token: CancelToken,
    model: IGetPosUsersModel
  ): Promise<IGetPosUsersResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "/pos-users",
      cancelToken: token,
      params: {
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SearchQuery: model.SearchQuery,
        SortDirection: model.SortDirection,
        SortField: model.SortField
      }
    };

    return axiosService.send<IGetPosUsersResponse>(config);
  }

  function getPosUser(token: CancelToken, model: IGetPosUserModel): Promise<IGetPosUserResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/pos-users/${model.PosUserName}`,
      cancelToken: token
    };

    return axiosService.send<IGetPosUserResponse>(config);
  }

  // Requests
  function getRequests(
    token: CancelToken,
    model: IGetRequestsModel
  ): Promise<IGetRequestsResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/requests`,
      cancelToken: token,
      paramsSerializer: function (params) {
        return qs.stringify(params, { allowDots: true, encode: false });
      },
      params: {
        RequestGroupId: model.RequestGroupId,
        CreatedDate: model.CreatedDate,
        RequestStatusId: model.RequestStatusId,
        StoreId: model.StoreId,
        WarehouseTypeId: model.WarehouseTypeId,
        MaterialCode: model.MaterialCode,
        ProductId: model.ProductId,
        Quantity: model.Quantity,
        ExpirationDate: model.ExpirationDate,
        SlipTypeId: model.SlipTypeId,
        RequestReasonId: model.RequestReasonId,
        SupplierCode: model.SupplierCode,
        CreatedBy: model.CreatedBy,
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SortDirection: model.SortDirection,
        SortField: model.SortField
      }
    };

    return axiosService.send<IGetRequestsResponse>(config);
  }

  function getRequestsToDownload(
    token: CancelToken,
    model: IGetRequestsToDownloadModel
  ): Promise<IGetRequestsResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/requests/download`,
      cancelToken: token,
      paramsSerializer: function (params) {
        return qs.stringify(params, { allowDots: true, encode: false });
      },
      params: {
        RequestGroupId: model.RequestGroupId,
        CreatedDate: model.CreatedDate,
        RequestStatusId: model.RequestStatusId,
        StoreId: model.StoreId,
        WarehouseTypeId: model.WarehouseTypeId,
        MaterialCode: model.MaterialCode,
        ProductId: model.ProductId,
        Quantity: model.Quantity,
        ExpirationDate: model.ExpirationDate,
        SlipTypeId: model.SlipTypeId,
        RequestReasonId: model.RequestReasonId,
        SupplierCode: model.SupplierCode,
        CreatedBy: model.CreatedBy
      }
    };

    return axiosService.send<IGetRequestsResponse>(config);
  }

  function getCompletedRequests(
    token: CancelToken,
    model: IGetRequestsModel
  ): Promise<IGetRequestsResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/requests/completed`,
      cancelToken: token,
      paramsSerializer: function (params) {
        return qs.stringify(params, { allowDots: true, encode: false });
      },
      params: {
        RequestGroupId: model.RequestGroupId,
        CreatedDate: model.CreatedDate,
        RequestStatusId: model.RequestStatusId,
        StoreId: model.StoreId,
        WarehouseTypeId: model.WarehouseTypeId,
        MaterialCode: model.MaterialCode,
        ProductId: model.ProductId,
        Quantity: model.Quantity,
        ExpirationDate: model.ExpirationDate,
        SlipTypeId: model.SlipTypeId,
        RequestReasonId: model.RequestReasonId,
        SupplierCode: model.SupplierCode,
        CreatedBy: model.CreatedBy,
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SortDirection: model.SortDirection,
        SortField: model.SortField
      }
    };

    return axiosService.send<IGetRequestsResponse>(config);
  }

  function getCompletedBlockageRequests(
    token: CancelToken,
    model: IGetCompletedBlockageRequestsModel
  ): Promise<IGetCompletedBlockageRequestsResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/requests/completed-blockage`,
      cancelToken: token,
      paramsSerializer: function (params) {
        return qs.stringify(params, { allowDots: true, encode: false });
      },
      params: {
        TransferId: model.TransferId,
        RequestGroupId: model.RequestGroupId,
        CreatedDate: model.CreatedDate,
        StoreId: model.StoreId,
        PalletId: model.PalletId,
        MaterialCode: model.MaterialCode,
        ProductId: model.ProductId,
        Quantity: model.Quantity,
        ExpirationDate: model.ExpirationDate,
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SortDirection: model.SortDirection,
        SortField: model.SortField
      }
    };

    return axiosService.send<IGetCompletedBlockageRequestsResponse>(config);
  }

  function getPurchaseReturnRequests(
    token: CancelToken,
    model: IGetRequestsModel
  ): Promise<IGetRequestsResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/requests/purchase-return`,
      cancelToken: token,
      paramsSerializer: function (params) {
        return qs.stringify(params, { allowDots: true, encode: false });
      },
      params: {
        RequestGroupId: model.RequestGroupId,
        CreatedDate: model.CreatedDate,
        RequestStatusId: model.RequestStatusId,
        StoreId: model.StoreId,
        WarehouseTypeId: model.WarehouseTypeId,
        MaterialCode: model.MaterialCode,
        ProductId: model.ProductId,
        Quantity: model.Quantity,
        ExpirationDate: model.ExpirationDate,
        SlipTypeId: model.SlipTypeId,
        RequestReasonId: model.RequestReasonId,
        SupplierCode: model.SupplierCode,
        CreatedBy: model.CreatedBy,
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SortDirection: model.SortDirection,
        SortField: model.SortField
      }
    };

    return axiosService.send<IGetRequestsResponse>(config);
  }

  function getPurchaseReturnRequestsToDownload(
    token: CancelToken,
    model: IGetPurchaseReturnRequestsToDownloadModel
  ): Promise<IGetRequestsResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/requests/purchase-return/download`,
      cancelToken: token,
      paramsSerializer: function (params) {
        return qs.stringify(params, { allowDots: true, encode: false });
      },
      params: {
        RequestGroupId: model.RequestGroupId,
        CreatedDate: model.CreatedDate,
        RequestStatusId: model.RequestStatusId,
        StoreId: model.StoreId,
        WarehouseTypeId: model.WarehouseTypeId,
        MaterialCode: model.MaterialCode,
        ProductId: model.ProductId,
        Quantity: model.Quantity,
        ExpirationDate: model.ExpirationDate,
        SlipTypeId: model.SlipTypeId,
        RequestReasonId: model.RequestReasonId,
        SupplierCode: model.SupplierCode,
        CreatedBy: model.CreatedBy
      }
    };

    return axiosService.send<IGetRequestsResponse>(config);
  }

  function getReconciliationRequests(
    token: CancelToken,
    model: IGetReconciliationRequestsModel
  ): Promise<IGetReconciliationRequestsResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/requests/reconciliation`,
      cancelToken: token,
      params: {
        StoreId: model.StoreId,
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SearchQuery: model.SearchQuery,
        SortDirection: model.SortDirection,
        SortField: model.SortField
      }
    };

    return axiosService.send<IGetReconciliationRequestsResponse>(config);
  }

  function getReconciliationRequestDetails(
    token: CancelToken,
    model: IGetReconciliationRequestDetailsModel
  ): Promise<Array<IGetReconciliationRequestDetailResponseModel>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/requests/reconciliation/details`,
      cancelToken: token,
      params: {
        StoreId: model.StoreId,
        ProductId: model.ProductId
      }
    };

    return axiosService.send<Array<IGetReconciliationRequestDetailResponseModel>>(config);
  }

  function getRequestInfoForUpdate(
    token: CancelToken,
    model: IGetRequestInfoForUpdateModel
  ): Promise<IGetRequestInfoForUpdateResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/requests/${model.RequestId}`,
      cancelToken: token
    };

    return axiosService.send<IGetRequestInfoForUpdateResponse>(config);
  }

  function addRequest(token: CancelToken, model: IAddRequestsModel): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/requests`,
      data: {
        StoreId: model.StoreId,
        WarehouseTypeId: model.WarehouseTypeId,
        Requests: model.Requests
      },
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function updateRequest(token: CancelToken, model: IUpdateRequestModel): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "put",
      url: `/requests/${model.RequestId}`,
      data: {
        RequestReasonId: model.RequestReasonId,
        SlipType: model.SlipType,
        SupplierCode: model.SupplierCode
      },
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function cancelRequests(token: CancelToken, model: ICancelRequestsModel): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "delete",
      data: model,
      url: "/requests",
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function approveRequests(token: CancelToken, model: IApproveRequestsModel): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "/requests/approve",
      data: model,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function bulkAddAndApproveRequests(token: CancelToken, model: Array<IBulkAddAndApproveRequestModel>): Promise<IBulkAddAndApproveRequestResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "/requests/bulk/add-and-approve",
      data: model,
      cancelToken: token
    };

    return axiosService.send<IBulkAddAndApproveRequestResponse>(config);
  }

  function approvePurchaseReturnRequests(
    token: CancelToken,
    model: IApprovePurchaseReturnRequestsModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "/requests/purchase-return/approve",
      data: model,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function approveReconciliationRequests(
    token: CancelToken,
    model: IApproveReconciliationRequestsModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/requests/reconciliation/approve`,
      data: model,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function revertCompletedRequest(
    token: CancelToken,
    model: IRevertCompletedRequest
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "put",
      url: `/requests/${model.RequestId}/completed/revert`,
      data: model,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  // Request Reasons
  function getRequestReasons(token: CancelToken): Promise<Array<IGetRequestReasonsResponse>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/requests/reasons`,
      cancelToken: token
    };

    return axiosService.send<Array<IGetRequestReasonsResponse>>(config);
  }

  function getRequestReasonFilters(token: CancelToken): Promise<Array<IGetRequestReasonsResponse>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/requests/reason-filters`,
      cancelToken: token
    };

    return axiosService.send<Array<IGetRequestReasonsResponse>>(config);
  }

  function addRequestReason(token: CancelToken, model: IAddRequestReasonModel): Promise<number> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/requests/reasons`,
      data: {
        Code: model.Code,
        Description: model.Description,
        SlipType: model.SlipType
      },
      cancelToken: token
    };

    return axiosService.send<number>(config);
  }

  function updateRequestReason(
    token: CancelToken,
    model: IUpdateRequestReasonModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "put",
      url: `/requests/reasons/${model.RequestReasonId}`,
      data: {
        Code: model.Code,
        Description: model.Description,
        SlipType: model.SlipType
      },
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function deleteRequestReason(
    token: CancelToken,
    model: IDeleteRequestReasonModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "delete",
      url: `/requests/reasons/${model.RequestReasonId}`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  // Request Slips
  function getRequestSlips(
    token: CancelToken,
    model: IGetRequestSlipsModel
  ): Promise<IGetRequestSlipsResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/requests/slips`,
      cancelToken: token,
      paramsSerializer: function (params) {
        return qs.stringify(params, { allowDots: true, encode: false });
      },
      params: model
    };

    return axiosService.send<IGetRequestSlipsResponse>(config);
  }

  function getRequestSlip(
    token: CancelToken,
    model: IGetRequestSlipModel
  ): Promise<IGetRequestSlipResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/requests/slips/${model.RequestSlipGroupId}`,
      cancelToken: token
    };

    return axiosService.send<IGetRequestSlipResponse>(config);
  }

  // Request Approval Rules
  function getRequestApprovalRules(
    token: CancelToken,
    model: IGetRequestApprovalRulesModel
  ): Promise<IGetRequestApprovalRulesResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/requests/rules`,
      cancelToken: token,
      params: {
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SearchQuery: model.SearchQuery
      }
    };

    return axiosService.send<IGetRequestApprovalRulesResponse>(config);
  }

  function addRequestApprovalRule(
    token: CancelToken,
    model: IAddRequestApprovalRuleModel
  ): Promise<number> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/requests/rules`,
      data: {
        ProductId: model.ProductId,
        RequestReasonId: model.RequestReasonId,
        StoreId: model.StoreId,
        WarehouseTypeId: model.WarehouseTypeId,
        SupplierCode: model.SupplierCode,
        StartValue: model.StartValue,
        EndValue: model.EndValue,
        ApprovalLevel: model.ApprovalLevel
      },
      cancelToken: token
    };

    return axiosService.send<number>(config);
  }

  function deleteRequestApprovalRule(
    token: CancelToken,
    model: IDeleteRequestApprovalRuleModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "delete",
      url: `/requests/rules/${model.RequestApprovalRuleId}`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  // Products
  function searchProducts(
    token: CancelToken,
    model: ISearchProductsModel
  ): Promise<Array<ISearchProductsResponse>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/products/search`,
      params: {
        searchText: model.SearchText
      },
      cancelToken: token
    };

    return axiosService.send<Array<ISearchProductsResponse>>(config);
  }

  // Suppliers
  function getAllSuppliers(token: CancelToken): Promise<Array<IGetAllSuppliersResponse>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/suppliers`,
      cancelToken: token
    };

    return axiosService.send<Array<IGetAllSuppliersResponse>>(config);
  }

  function getSupplierOrderCollection(
    token: CancelToken,
    model: IGetSupplierOrderCollectionModel
  ): Promise<IGetSupplierOrderCollectionResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/suppliers/orders/${model.OrderId}/collection`,
      cancelToken: token
    };

    return axiosService.send<IGetSupplierOrderCollectionResponse>(config);
  }

  function getSupplierOrderProductByCode(
    token: CancelToken,
    model: IGetSupplierOrderProductByCodeModel
  ): Promise<IGetSupplierOrderProductByCodeResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/suppliers/orders/${model.OrderId}/products/${model.Code}`,
      cancelToken: token
    };

    return axiosService.send<IGetSupplierOrderProductByCodeResponse>(config);
  }

  function saveSupplierOrderCollection(
    token: CancelToken,
    model: ISaveSupplierOrderCollectionModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "/suppliers/orders/collections/save",
      cancelToken: token,
      data: {
        OrderId: model.OrderId,
        DispatchNo: model.DispatchNo,
        DispatchDate: model.DispatchDate,
        CollectedProducts: model.CollectedProducts
      }
    };

    return axiosService.send<boolean>(config);
  }

  function checkSupplierOrderCollections(
    token: CancelToken,
    model: ICheckSupplierOrderCollectionsModel
  ): Promise<ICheckSupplierOrderCollectionsResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/suppliers/orders/${model.OrderId}/collections/dispatches/${model.DispatchNo}`,
      cancelToken: token
    };

    return axiosService.send<ICheckSupplierOrderCollectionsResponse>(config);
  }

  // Request Dispatches
  function getRequestDispatches(
    token: CancelToken,
    model: IGetRequestDispatchesModel
  ): Promise<IGetRequestDispatchesResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/requests/dispatches`,
      cancelToken: token,
      paramsSerializer: function (params) {
        return qs.stringify(params, { allowDots: true, encode: false });
      },
      params: {
        DispatchDate: model.DispatchDate,
        DispatchNo: model.DispatchNo,
        EDispatchNo: model.EDispatchNo,
        SlipTypeId: model.SlipTypeId,
        StoreId: model.StoreId,
        WarehouseTypeId: model.WarehouseTypeId,
        RequestGroupId: model.RequestGroupId,
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SortDirection: model.SortDirection,
        SortField: model.SortField
      }
    };

    return axiosService.send<IGetRequestDispatchesResponse>(config);
  }
  // Request Dispatches
  function getRequestDispatchesToDownload(
    token: CancelToken,
    model: IGetRequestDispatchesModel
  ): Promise<IGetRequestDispatchesResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/requests/dispatches/download`,
      cancelToken: token,
      paramsSerializer: function (params) {
        return qs.stringify(params, { allowDots: true, encode: false });
      },
      params: {
        DispatchDate: model.DispatchDate,
        DispatchNo: model.DispatchNo,
        EDispatchNo: model.EDispatchNo,
        SlipTypeId: model.SlipTypeId,
        StoreId: model.StoreId,
        WarehouseTypeId: model.WarehouseTypeId,
        RequestGroupId: model.RequestGroupId,
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SortDirection: model.SortDirection,
        SortField: model.SortField
      }
    };

    return axiosService.send<IGetRequestDispatchesResponse>(config);
  }

  function getCompletedSupplierOrderCollection(
    token: CancelToken,
    model: IGetCompletedSupplierOrderCollectionModel
  ): Promise<IGetCompletedSupplierOrderCollectionResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/suppliers/orders/collections/${model.CollectionId}/completed`,
      cancelToken: token
    };

    return axiosService.send<IGetCompletedSupplierOrderCollectionResponse>(config);
  }

  function getRequestDispatch(
    token: CancelToken,
    model: IGetRequestDispatchModel
  ): Promise<IGetRequestDispatchResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/requests/dispatches/${model.DispatchNo}`,
      cancelToken: token
    };

    return axiosService.send<IGetRequestDispatchResponse>(config);
  }

  // Store Transfer Dispatches
  function getStoreTransferDispatches(
    token: CancelToken,
    model: IGetStoreTransferDispatchesModel
  ): Promise<IGetStoreTransferDispatchesResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/transfer-dispatches`,
      cancelToken: token,
      paramsSerializer: function (params) {
        return qs.stringify(params, { allowDots: true, encode: false });
      },
      params: model
    };

    return axiosService.send<IGetStoreTransferDispatchesResponse>(config);
  }

  function getStoreTransferDispatch(
    token: CancelToken,
    model: IGetStoreTransferDispatchModel
  ): Promise<IGetStoreTransferDispatchResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/transfer-dispatch/${model.DispatchNo}`,
      cancelToken: token
    };

    return axiosService.send<IGetStoreTransferDispatchResponse>(config);
  }

  function addStoreTransfersWithProducts(
    token: CancelToken,
    model: Array<IAddStoreTransferWithProductModel>
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "/stores/transfers/with-products",
      cancelToken: token,
      data: model
    };

    return axiosService.send<boolean>(config);
  }

  function checkAddStoreTransfersWithProducts(
    token: CancelToken,
    model: Array<ICheckAddStoreTransferWithProductModel>
  ): Promise<Array<number>> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "/stores/transfers/check",
      cancelToken: token,
      data: model
    };

    return axiosService.send<Array<number>>(config);
  }

  function updateSupplierOrderCollection(
    token: CancelToken,
    model: IUpdateSupplierCollectionModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "put",
      url: `/suppliers/orders/collections/${model.CollectionId}`,
      cancelToken: token,
      data: model
    };

    return axiosService.send<boolean>(config);
  }

  function updateSupplierOrderCollectionProduct(
    token: CancelToken,
    model: IUpdateSupplierCollectionProductModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "put",
      url: `/suppliers/orders/${model.OrderId}/collections/${model.CollectionId}/products`,
      cancelToken: token,
      data: model
    };

    return axiosService.send<boolean>(config);
  }

  function addSupplierOrderCollectionProduct(
    token: CancelToken,
    model: IUpdateSupplierCollectionProductModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/suppliers/orders/${model.OrderId}/collections/${model.CollectionId}/products`,
      cancelToken: token,
      data: model
    };

    return axiosService.send<boolean>(config);
  }

  function updateOrderCorrectionPayment(
    token: CancelToken,
    model: IUpdateOrderCorrectionPaymentModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "patch",
      url: `/orders/${model.OrderId}/correction/payment`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function cancelStoreTransfer(token: CancelToken, transferId: number): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "put",
      url: `/stores/transfers/${transferId}/cancel`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function getStoreReturnableEmptyCarboys(
    token: CancelToken,
    model: IGetStoreReturnableEmptyCarboysModel
  ): Promise<IGetStoreReturnableEmptyCarboysResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/${model.StoreId}/returnable-empty-carboys`,
      cancelToken: token,
      params: {
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SearchQuery: model.SearchQuery
      }
    };

    return axiosService.send<IGetStoreReturnableEmptyCarboysResponse>(config);
  }

  function updateStoreReturnableEmptyCarboy(
    token: CancelToken,
    model: IUpdateReturnableEmptyCarboyModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "put",
      url: `/stores/${model.StoreId}/returnable-empty-carboys`,
      cancelToken: token,
      data: {
        Id: model.Id,
        OrderId: model.OrderId,
        CarboyBrandId: model.CarboyBrandId
      }
    };

    return axiosService.send<boolean>(config);
  }

  function getStoreTransferDescriptions(
    token: CancelToken,
    model: IGetStoreTransferDescriptionsModel
  ): Promise<Array<IGetStoreTransferDescriptionsResponse>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/transfers/descriptions?isShowAll=${model.IsShowAll}`,
      cancelToken: token
    };

    return axiosService.send<Array<IGetStoreTransferDescriptionsResponse>>(config);
  }

  function addCashCountReport(
    token: CancelToken,
    model: IAddCashCountReportModel
  ): Promise<IAddCashCountReportResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/cash-count-reports/${model.StoreId}`,
      cancelToken: token,
      data: model
    };

    return axiosService.send<IAddCashCountReportResponse>(config);
  }

  function getCashCountReport(
    token: CancelToken,
    model: IGetCashCountReportModel
  ): Promise<IGetCashCountReportResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/cash-count-reports/${model.ReportId}`,
      cancelToken: token
    };

    return axiosService.send<IGetCashCountReportResponse>(config);
  }

  function getCurrencyUnits(token: CancelToken): Promise<Array<IGetCurrencyUnit>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/cash-count-reports/currency-units`,
      cancelToken: token
    };

    return axiosService.send<Array<IGetCurrencyUnit>>(config);
  }

  function addCurrencyUnitQuantity(
    token: CancelToken,
    model: IAddCurrencyUnitQuantityModel
  ): Promise<IGetCashCountReportResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/cash-count-reports/${model.ReportId}/currency-unit`,
      cancelToken: token,
      data: model
    };

    return axiosService.send<IGetCashCountReportResponse>(config);
  }

  function deleteCurrencyUnitQuantity(
    token: CancelToken,
    model: IDeleteCurrencyUnitQuantityModel
  ): Promise<IGetCashCountReportResponse> {
    const config: AxiosRequestConfig = {
      method: "delete",
      url: `/cash-count-reports/${model.ReportId}/currency-unit/${model.CurrencyUnitId}`,
      cancelToken: token
    };

    return axiosService.send<IGetCashCountReportResponse>(config);
  }

  function updateCashCountReport(
    token: CancelToken,
    model: IUpdateCashCountReportModel
  ): Promise<IGetCashCountReportResponse> {
    const config: AxiosRequestConfig = {
      method: "put",
      url: `/cash-count-reports/${model.ReportId}`,
      cancelToken: token,
      data: model
    };

    return axiosService.send<IGetCashCountReportResponse>(config);
  }

  function getCashCountReports(
    token: CancelToken,
    model: IGetCashCountReportsModel
  ): Promise<IGetCashCountReportsResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/cash-count-reports/${model.StoreId}/list`,
      cancelToken: token,
      data: model
    };

    return axiosService.send<IGetCashCountReportsResponse>(config);
  }

  function sendCashCountReportForApproval(
    token: CancelToken,
    model: ISendCashCountReportForApprovalModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/cash-count-reports/${model.ReportId}/send-for-approval`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function approveCashCountReport(
    token: CancelToken,
    model: IApproveCashCountReportModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/cash-count-reports/${model.ReportId}/approve`,
      cancelToken: token
    };

    return axiosService.send<boolean>(config);
  }

  function declineCashCountReport(
    token: CancelToken,
    model: IDeclineCashCountReportModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/cash-count-reports/${model.ReportId}/decline`,
      cancelToken: token,
      data: model
    };

    return axiosService.send<boolean>(config);
  }

  function getStoreCashCountReportConfigs(
    token: CancelToken,
    model: IGetStoreCashCountReportConfigsModel
  ): Promise<IGetStoreCashCountReportConfigsResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/cash-count-reports/store-configs`,
      cancelToken: token,
      paramsSerializer: function (params) {
        return qs.stringify(params, { allowDots: true, encode: false });
      },
      params: model
    };

    return axiosService.send<IGetStoreCashCountReportConfigsResponse>(config);
  }

  function addStoreCashCountReportConfig(
    token: CancelToken,
    model: IAddStoreCashCountReportConfigModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/cash-count-reports/store/${model.StoreId}/config`,
      cancelToken: token,
      data: model
    };

    return axiosService.send<boolean>(config);
  }

  function updateStoreCashCountReportConfig(
    token: CancelToken,
    model: IUpdateStoreCashCountReportConfigModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "put",
      url: `/cash-count-reports/store/${model.StoreId}/config`,
      cancelToken: token,
      data: model
    };

    return axiosService.send<boolean>(config);
  }

  function getStoreExpirationChecks(
    token: CancelToken,
    model: IGetStoreExpirationChecksModel
  ): Promise<IGetStoreExpirationChecksResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/${model.StoreId}/expiration-checks/${model.PeriodTypeId}`,
      cancelToken: token,
      params: {
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SearchQuery: model.SearchQuery
      }
    };

    return axiosService.send<IGetStoreExpirationChecksResponse>(config);
  }

  function getStoreExpirationCheckWithProducts(
    token: CancelToken,
    model: IGetStoreExpirationCheckWithProductsModel
  ): Promise<IGetStoreExpirationCheckWithProductsResponse> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/expiration-checks/${model.ExpirationCheckId}`,
      cancelToken: token,
      params: {
        SearchQuery: model.SearchQuery
      }
    };

    return axiosService.send<IGetStoreExpirationCheckWithProductsResponse>(config);
  }

  function addStoreExpirationCheck(
    token: CancelToken,
    model: IAddStoreExpirationCheckModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/${model.StoreId}/expiration-checks`,
      cancelToken: token,
      data: {
        PeriodType: model.PeriodTypeId,
        ExpirationCheckName: model.ExpirationCheckName
      }
    };

    return axiosService.send<boolean>(config);
  }

  function getStoreExpirationCheckProductDetail(
    token: CancelToken,
    model: IGetStoreExpirationCheckProductDetailModel
  ): Promise<Array<IGetStoreExpirationCheckProductDetailResponse>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/expiration-checks/${model.ExpirationCheckId}/products/${model.MaterialCode}`,
      cancelToken: token,
      params: {}
    };

    return axiosService.send<Array<IGetStoreExpirationCheckProductDetailResponse>>(config);
  }

  function updateStoreExpirationCheckProduct(
    token: CancelToken,
    model: IUpdateStoreExpirationCheckProductModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/expiration-checks/${model.ExpirationCheckId}/products/${model.MaterialCode}`,
      cancelToken: token,
      data: {
        ExpirationDates: model.ExpirationDates
      }
    };

    return axiosService.send<boolean>(config);
  }

  function updateStoreExpirationCheckStatus(
    token: CancelToken,
    model: IUpdateStoreExpirationCheckStatusModel
  ): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: "put",
      url: `/stores/expiration-checks/${model.ExpirationCheckId}/status`,
      cancelToken: token,
      data: {
        Status: model.StatusTypeId
      }
    };

    return axiosService.send<boolean>(config);
  }

  function getStoreExpirationCheckReport(
    token: CancelToken,
    model: IGetStoreExpirationCheckReportModel
  ): Promise<Array<IGetStoreExpirationCheckReportResponse>> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/stores/expiration-checks/${model.ExpirationCheckId}/report`,
      cancelToken: token,
      params: {}
    };

    return axiosService.send<Array<IGetStoreExpirationCheckReportResponse>>(config);
  }

  function getStoreProductProductionQuantityReport(
    token: CancelToken,
    model: IGetStoreProductProductionQuantityReportModel
  ): Promise<IGetStoreProductProductionQuantityReportResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/product-production-quantity/report`,
      cancelToken: token,
      data: {
        StoreIds: model.StoreIds,
        ReportDate: model.ReportDate,
        PageNumber: model.PageNumber,
        PageSize: model.PageSize,
        SortField: model.SortField,
        SortDirection: model.SortDirection
      }
    };

    return axiosService.send<IGetStoreProductProductionQuantityReportResponse>(config);
  }

  function getStoreProductProductionQuantityReportDownload(
    token: CancelToken,
    model: IGetStoreProductProductionQuantityReportDownloadModel
  ): Promise<Array<IGetStoreProductProductionQuantityReportDownloadResponse>> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/stores/product-production-quantity/report/download`,
      cancelToken: token,
      data: {
        StoreIds: model.StoreIds,
        ReportDate: model.ReportDate
      }
    };

    return axiosService.send<Array<IGetStoreProductProductionQuantityReportDownloadResponse>>(config);
  }

  const value: IAdminServiceContext = {
    // Users
    getSample: getSample,
    getPermissions: getPermissions,
    getUsers: getUsers,
    getMyInfo: getMyInfo,
    getMyStores: getMyStores,
    getUserStores: getUserStores,
    getUser: getUser,
    addNewUser: addNewUser,
    updateUser: updateUser,
    deleteUser: deleteUser,
    getAllStoresWithUserAssignments: getAllStoresWithUserAssignments,
    saveUserStores: saveUserStores,
    getAllPermissionsWithUserAssignments: getAllPermissionsWithUserAssignments,
    saveUserPermissions: saveUserPermissions,

    // Stores
    getStoreProducts: getStoreProducts,
    getStoreProductsToDownload: getStoreProductsToDownload,
    getStoreProductExpirationDateReport: getStoreProductExpirationDateReport,
    getStoreProductExpirationDateReportToDownload: getStoreProductExpirationDateReportToDownload,
    getStorePaymentReport: getStorePaymentReport,
    getStorePaymentReportToDownload: getStorePaymentReportToDownload,
    getStoreDetailedPaymentReport: getStoreDetailedPaymentReport,
    getStoreDetailedPaymentReportToDownload: getStoreDetailedPaymentReportToDownload,
    getDetailedApprovedByPaymentReportToDownload: getDetailedApprovedByPaymentReportToDownload,
    getStoreProduct: getStoreProduct,
    getWarehouseProduct: getWarehouseProduct,
    getStoreProductSuppliers: getStoreProductSuppliers,
    getStoreActiveOrders: getStoreActiveOrders,
    getStoreInventories: getStoreInventories,
    addStoreInventory: addStoreInventory,
    getStoreInventoryCollections: getStoreInventoryCollections,
    getStoreInventoryCollection: getStoreInventoryCollection,
    getStoreInventory: getStoreInventory,
    getStoreInventoryPreview: getStoreInventoryPreview,
    getStoreInventoryPreviewToDownload: getStoreInventoryPreviewToDownload,
    getStoreCompletedInventoryToDownload: getStoreCompletedInventoryToDownload,
    addStoreInventoryProcessedStocks: addStoreInventoryProcessedStocks,
    getStoreStockDifferenceToDownload: getStoreStockDifferenceToDownload,
    startStoreInventoryCollection: startStoreInventoryCollection,
    stopStoreInventoryCollection: stopStoreInventoryCollection,
    completeStoreInventoryCollection: completeStoreInventoryCollection,
    cancelStoreInventoryCollection: cancelStoreInventoryCollection,
    getStoreAllInventoryCollectionProducts: getStoreAllInventoryCollectionProducts,
    getStoreInventoryCollectionProducts: getStoreInventoryCollectionProducts,
    deleteStoreInventoryCollectionProducts: deleteStoreInventoryCollectionProducts,
    addOrUpdateStoreInventoryCollectionProduct: addOrUpdateStoreInventoryCollectionProduct,
    completeStoreInventory: completeStoreInventory,
    cancelStoreInventory: cancelStoreInventory,
    approveStoreInventory: approveStoreInventory,
    updateStoreInventoryStatus: updateStoreInventoryStatus,
    updateStoreInventoryCollectionProduct: updateStoreInventoryCollectionProduct,
    deleteStoreInventoryCollectionProduct: deleteStoreInventoryCollectionProduct,
    getStoreReturnedOrders: getStoreReturnedOrders,
    getStoreOfflineOrders: getStoreOfflineOrders,
    addWarehouseTransfer: addWarehouseTransfer,
    addWarehouseTransferProduct: addWarehouseTransferProduct,
    deleteWarehouseTransfer: deleteWarehouseTransfer,
    deleteWarehouseTransferProduct: deleteWarehouseTransferProduct,
    saveWarehouseTransfer: saveWarehouseTransfer,
    getStoreSupplierOrders: getStoreSupplierOrders,
    getStoreTransfers: getStoreTransfers,
    addStoreTransfer: addStoreTransfer,
    getStoreTransfer: getStoreTransfer,
    addStoreTransferProduct: addStoreTransferProduct,
    addStoreTransferProducts: addStoreTransferProducts,
    deleteStoreTransferProduct: deleteStoreTransferProduct,
    deleteStoreTransferProducts: deleteStoreTransferProducts,
    saveStoreTransfer: saveStoreTransfer,
    bulkSaveStoreTransfer: bulkSaveStoreTransfer,
    partiallySaveStoreTransfer: partiallySaveStoreTransfer,
    bulkPartiallySaveStoreTransfer: bulkPartiallySaveStoreTransfer,
    updateStoreTransfer: updateStoreTransfer,
    updateStoreTransferProduct: updateStoreTransferProduct,
    getStoreOutgoingTransfers: getStoreOutgoingTransfers,
    getStoreOutgoingTransfer: getStoreOutgoingTransfer,
    getStoreTransferProductByCode: getStoreTransferProductByCode,
    saveStoreOutgoingTransferCollection: saveStoreOutgoingTransferCollection,
    getStoreIncomingTransfers: getStoreIncomingTransfers,
    getStoreIncomingTransferCollection: getStoreIncomingTransferCollection,
    getStoreIncomingTransferCollectionIdByPalletId: getStoreIncomingTransferCollectionIdByPalletId,
    saveStoreIncomingTransferCollection: saveStoreIncomingTransferCollection,
    transferStockBetweenProducts: transferStockBetweenProducts,
    addStoreTransfersWithProducts: addStoreTransfersWithProducts,
    checkAddStoreTransfersWithProducts: checkAddStoreTransfersWithProducts,
    getStoreSupplierOrderDispatches: getStoreSupplierOrderDispatches,
    cancelStoreTransfer: cancelStoreTransfer,
    getStoreReturnableEmptyCarboys: getStoreReturnableEmptyCarboys,
    updateStoreReturnableEmptyCarboy: updateStoreReturnableEmptyCarboy,
    cancelStoreTransfers: cancelStoreTransfers,
    getStoreTransferDescriptions: getStoreTransferDescriptions,
    getStoreExpirationChecks: getStoreExpirationChecks,
    getStoreExpirationCheckWithProducts: getStoreExpirationCheckWithProducts,
    addStoreExpirationCheck: addStoreExpirationCheck,
    getStoreExpirationCheckProductDetail: getStoreExpirationCheckProductDetail,
    updateStoreExpirationCheckProduct: updateStoreExpirationCheckProduct,
    updateStoreExpirationCheckStatus: updateStoreExpirationCheckStatus,
    getStoreExpirationCheckReport: getStoreExpirationCheckReport,
    getStoreCompletedTransfers:getStoreCompletedTransfers,
    getStoreCompletedTransfer:getStoreCompletedTransfer,
    getStoreProductProductionQuantityReport: getStoreProductProductionQuantityReport,
    getStoreProductProductionQuantityReportDownload: getStoreProductProductionQuantityReportDownload,

    // Orders
    getOrderCollection: getOrderCollection,
    deleteOrderCollection: deleteOrderCollection,
    deleteOrderCollectionProduct: deleteOrderCollectionProduct,
    addOrderCollectionProduct: addOrderCollectionProduct,
    addOrderCollectionWasteProduct: addOrderCollectionWasteProduct,
    saveOrderCollection: saveOrderCollection,
    getOrderRefundCollection: getOrderRefundCollection,
    saveOrderRefund: saveOrderRefund,
    startOrderRefund: startOrderRefund,
    saveOfflineOrderPayment: saveOfflineOrderPayment,
    updateOrderCorrectionPayment: updateOrderCorrectionPayment,

    // Pos Users
    getPosUsers: getPosUsers,
    getPosUser: getPosUser,

    // Requests
    getRequests: getRequests,
    getRequestsToDownload: getRequestsToDownload,
    getCompletedRequests: getCompletedRequests,
    getCompletedBlockageRequests: getCompletedBlockageRequests,
    getPurchaseReturnRequests: getPurchaseReturnRequests,
    getPurchaseReturnRequestsToDownload: getPurchaseReturnRequestsToDownload,
    getRequestInfoForUpdate: getRequestInfoForUpdate,
    addRequest: addRequest,
    updateRequest: updateRequest,
    cancelRequests: cancelRequests,
    approveRequests: approveRequests,
    bulkAddAndApproveRequests: bulkAddAndApproveRequests,
    approvePurchaseReturnRequests: approvePurchaseReturnRequests,
    getRequestReasons: getRequestReasons,
    getRequestReasonFilters: getRequestReasonFilters,
    addRequestReason: addRequestReason,
    updateRequestReason: updateRequestReason,
    deleteRequestReason: deleteRequestReason,
    getRequestSlips: getRequestSlips,
    getRequestSlip: getRequestSlip,
    getRequestApprovalRules: getRequestApprovalRules,
    addRequestApprovalRule: addRequestApprovalRule,
    deleteRequestApprovalRule: deleteRequestApprovalRule,
    revertCompletedRequest: revertCompletedRequest,
    getReconciliationRequests: getReconciliationRequests,
    getReconciliationRequestDetails: getReconciliationRequestDetails,
    approveReconciliationRequests: approveReconciliationRequests,

    // Products
    searchProducts: searchProducts,

    // Suppliers
    getAllSuppliers: getAllSuppliers,
    getSupplierOrderCollection: getSupplierOrderCollection,
    getSupplierOrderProductByCode: getSupplierOrderProductByCode,
    saveSupplierOrderCollection: saveSupplierOrderCollection,
    checkSupplierOrderCollections: checkSupplierOrderCollections,
    getCompletedSupplierOrderCollection: getCompletedSupplierOrderCollection,
    updateSupplierOrderCollection: updateSupplierOrderCollection,
    updateSupplierOrderCollectionProduct: updateSupplierOrderCollectionProduct,
    addSupplierOrderCollectionProduct: addSupplierOrderCollectionProduct,

    // Request Dispatches
    getRequestDispatches: getRequestDispatches,
    getRequestDispatch: getRequestDispatch,
    getRequestDispatchesToDownload: getRequestDispatchesToDownload,

    // Store Transfer Dispatches
    getStoreTransferDispatches: getStoreTransferDispatches,
    getStoreTransferDispatch: getStoreTransferDispatch,

    //Verifone Actions
    getVerifoneTransactionLogs: getVerifoneTransactionLogsReport,
    getVerifoneTransactionLogsReportToDownload: getVerifoneTransactionLogsReportToDownload,

    //Cash Count Reports
    addCashCountReport: addCashCountReport,
    getCashCountReport: getCashCountReport,
    getCurrencyUnits: getCurrencyUnits,
    addCurrencyUnitQuantity: addCurrencyUnitQuantity,
    deleteCurrencyUnitQuantity: deleteCurrencyUnitQuantity,
    updateCashCountReport: updateCashCountReport,
    getCashCountReports: getCashCountReports,
    sendCashCountReportForApproval: sendCashCountReportForApproval,
    approveCashCountReport: approveCashCountReport,
    declineCashCountReport: declineCashCountReport,
    getStoreCashCountReportConfigs: getStoreCashCountReportConfigs,
    addStoreCashCountReportConfig: addStoreCashCountReportConfig,
    updateStoreCashCountReportConfig: updateStoreCashCountReportConfig
  };

  return (
    <AdminServiceContext.Provider value={value}>{props.children}</AdminServiceContext.Provider>
  );
}

export function useAdminService(): IAdminServiceContext {
  return useContext(AdminServiceContext);
}
