import React from "react";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  gridItem: {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem"
  }
});

interface IProps {
  onSidebarClose: () => void;
}

function SidebarLogo(props: IProps) {
  const classes = useStyles();

  return (
    <Grid container justify="center">
      <Grid item className={classes.gridItem}>
        <Button component={Link} to="/" onClick={props.onSidebarClose} disableElevation={true}>
          <img
            width="200"
            src="https://cdn.hangarheroes.com/images/hangar3.svg"
            alt="Hangar Logo"
          />
        </Button>
      </Grid>
    </Grid>
  );
}

export default SidebarLogo;
