import React, { createContext, useContext, useState } from "react";
import { Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Alert } from "@material-ui/lab";

interface ISnackbarContext {
  open: (message: string, type: string, preWrap?: boolean) => void;
  close: () => void;
}

const useStyles = makeStyles({
  preWrappedTextContent: {
    whiteSpace: "pre-wrap"
  }
});

const SnackbarContext = createContext<ISnackbarContext>({} as ISnackbarContext);

export function useSnackbarGlobal(): ISnackbarContext {
  return useContext(SnackbarContext);
}

interface IProps {
  children: React.ReactNode;
}

export function SnackbarGlobal(props: IProps) {
  const classes = useStyles();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<string>("info");
  const [isPreWrapEnabled, setIsPreWrapEnabled] = useState<boolean>(false);

  function handleSnackbarOpen(message: string, type: string, preWrap: boolean = false): void {
    setType(type);
    setMessage(message);
    setIsSnackbarOpen(true);
    setIsPreWrapEnabled(preWrap);
  }

  function handleSnackbarClose(): void {
    setIsSnackbarOpen(false);
  }

  const value: ISnackbarContext = {
    open: handleSnackbarOpen,
    close: handleSnackbarClose
  };

  return (
    <SnackbarContext.Provider value={value}>
      {props.children}
      <Snackbar
        className={isPreWrapEnabled ? classes.preWrappedTextContent : ""}
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          severity={
            type === "info"
              ? "info"
              : type === "warning"
              ? "warning"
              : type === "success"
              ? "success"
              : type === "error"
              ? "error"
              : "info"
          }
          variant="filled"
        >
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
}
