import { ILocale } from "../../../interfaces/ILocale";

export const locales: Array<ILocale> = [
  {
    name: "tr-TR",
    messages: {
      "dashboard": "Pano",
      "products": "Ürün Listesi",
      "users": "Üyeler",
      "active-orders": "Aktif Siparişler",
      "returned-orders": "İade Siparişler",
      "pos-users": "Pos Kullanıcıları",
      "requests": "Talepler",
      "add": "Ekle",
      "purchases": "Satın Alma Giriş",
      "transfers": "Transfer İstekleri",
      "outgoing-transfers": "Giden Depo Transferleri",
      "incoming-transfers": "Gelen Depo Transferleri",
      "inventories": "Sayım Modülü",
      "expiration-check": "SKT Kontrolü",
      "collections": "Raf Barkodları",
      "purchase-dispatches": "İrsaliye Düzenle",
      "cash-count-reports": "Kasa Sayım Tutanakları",
      "new": "Yeni",
      "completed-transfers":"Tamamlanan Transferler"
    }
  },
  {
    name: "en-US",
    messages: {
      "dashboard": "Dashboard",
      "products": "Products List",
      "users": "Users",
      "active-orders": "Active Orders",
      "returned-orders": "Returned Orders",
      "pos-users": "Pos Users",
      "requests": "Requests",
      "add": "Add",
      "purchases": "New Purchase",
      "transfers": "Transfer Requests",
      "outgoing-transfers": "Outgoing Store Transfers",
      "incoming-transfers": "Incoming Store Transfers",
      "inventories": "Inventory Module",
      "expiration-check": "Exp. Date Check",
      "collections": "Shelf Barcodes",
      "purchase-dispatches": "Edit Dispatches",
      "cash-count-reports": "Cash Count Reports",
      "new": "New",
      "completed-transfers":"Completed Transfers"
    }
  },
  {
    name: "ar-AE",
    messages: {
      "dashboard": "لوحة القيادة",
      "products": "قائمة المنتجات",
      "users": "عضوية",
      "active-orders": "أوامر نشطة",
      "returned-orders": "أوامر مرتجعة",
      "pos-users": "مستخدمي نقاط البيع",
      "requests": "الطلبات",
      "add": "أضف",
      "purchases": "شراء جديدة",
      "transfers": "طلبات التحويل",
      "outgoing-transfers": "تحويلات المتجر الصادرة",
      "incoming-transfers": "تحويلات المستودعات الواردة",
      "inventories": "وحدة الجرد",
      "expiration-check": "تحقق من تاريخ انتهاء الصلاحية",
      "collections": "الباركود الجرف",
      "purchase-dispatches": "تحرير الإرساليات",
      "cash-count-reports": "تقارير حساب النقد",
      "new": "جديد",
      "completed-transfers":"عمليات النقل المكتملة"
    }
  }
];
