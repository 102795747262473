import React, { lazy, LazyExoticComponent, Suspense, useEffect, useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { useAdminService } from "../services/AdminService/AdminService";
import { useAuthGlobal } from "../globals/AuthGlobal/AuthGlobal";
import { useCancelToken } from "../services/AxiosService/AxiosService";
import Layout from "../components/Layout/Layout";
import checkPermission from "../utility/helpers/PermissionHelper";

const DashboardPage = lazy(() => import("../pages/DashboardPage/DashboardPage"));
const LoginPage = lazy(() => import("../pages/LoginPage/LoginPage"));
const NotFoundPage = lazy(() => import("../pages/NotFoundPage/NotFoundPage"));
const ActiveOrdersPage = lazy(() => import("../pages/ActiveOrdersPage/ActiveOrdersPage"));
const ReturnedOrdersPage = lazy(() => import("../pages/ReturnedOrdersPage/ReturnedOrdersPage"));
const ReturnedOrderPage = lazy(() => import("../pages/ReturnedOrderPage/ReturnedOrderPage"));
const MyAccountPage = lazy(() => import("../pages/MyAccountPage/MyAccountPage"));
const ProductsPage = lazy(() => import("../pages/ProductsPage/ProductsPage"));
const ProductPage = lazy(() => import("../pages/ProductPage/ProductPage"));
const ProductStockTransferPage = lazy(() =>
  import("../pages/ProductStockTransferPage/ProductStockTransferPage")
);
const ExpirationDateReportPage = lazy(() =>
  import("../pages/ExpirationDateReportPage/ExpirationDateReportPage")
);
const InventoriesPage = lazy(() => import("../pages/InventoriesPage/InventoriesPage"));
const InventoryPage = lazy(() => import("../pages/InventoryPage/InventoryPage"));
const InventoryCollectionPage = lazy(() =>
  import("../pages/InventoryCollectionPage/InventoryCollectionPage")
);
const InventoryPreviewPage = lazy(() =>
  import("../pages/InventoryPreviewPage/InventoryPreviewPage")
);

const ExpirationChecksPage = lazy(() =>
  import("../pages/ExpirationChecksPage/ExpirationChecksPage")
);
const ExpirationCheckPage = lazy(() => import("../pages/ExpirationCheckPage/ExpirationCheckPage"));

const UsersPage = lazy(() => import("../pages/UsersPage/UsersPage"));
const ActiveOrderPage = lazy(() => import("../pages/ActiveOrderPage/ActiveOrderPage"));
const EditUserPage = lazy(() => import("../pages/EditUserPage/EditUserPage"));
const NewUserPage = lazy(() => import("../pages/NewUserPage/NewUserPage"));
const PosUsersPage = lazy(() => import("../pages/PosUsersPage/PosUsersPage"));
const EditPosUserPage = lazy(() => import("../pages/PosUserPage/PosUserPage"));
const RequestPage = lazy(() => import("../pages/RequestPage/RequestPage"));
const RequestsPage = lazy(() => import("../pages/RequestsPage/RequestsPage"));
const PurchasesPage = lazy(() => import("../pages/PurchasesPage/PurchasesPage"));
const PurchasesNewPage = lazy(() => import("../pages/PurchasesNewPage/PurchasesNewPage"));
const CompletedRequestsPage = lazy(() =>
  import("../pages/CompletedRequestsPage/CompletedRequestsPage")
);
const CompletedBlockageRequestsPage = lazy(() =>
  import("../pages/CompletedBlockageRequestsPage/CompletedBlockageRequestsPage")
);
const PurchaseReturnRequestsPage = lazy(() =>
  import("../pages/PurchaseReturnRequestsPage/PurchaseReturnRequestsPage")
);
const ReconciliationRequestsPage = lazy(() =>
  import("../pages/ReconciliationRequestsPage/ReconciliationRequestsPage")
);
const RequestReasonsPage = lazy(() => import("../pages/RequestReasonsPage/RequestReasonsPage"));
const RequestApprovalRulesPage = lazy(() =>
  import("../pages/RequestApprovalRulesPage/RequestApprovalRulesPage")
);
const RequestSlipsPage = lazy(() => import("../pages/RequestSlipsPage/RequestSlipsPage"));
const ApprovePaymentsPage = lazy(() => import("../pages/ApprovePaymentsPage/ApprovePaymentsPage"));
const PaymentReportsPage = lazy(() => import("../pages/PaymentReportsPage/PaymentReportsPage"));

const VerifoneReportsPage = lazy(() => import("../pages/VerifoneReportPage/VerifoneReportsPage"));

const MyStoreTransfersPage = lazy(() =>
  import("../pages/MyStoreTransfersPage/MyStoreTransfersPage")
);
const RequestDispatchesPage = lazy(() =>
  import("../pages/RequestDispatchesPage/RequestDispatchesPage")
);
const TransfersPage = lazy(() => import("../pages/TransfersPage/TransfersPage"));
const TransferPage = lazy(() => import("../pages/TransferPage/TransferPage"));
const CompletedTransfersPage = lazy(() => import("../pages/CompletedTransfersPage/CompletedTranfersPage"));
const CompletedTransferPage = lazy(() => import("../pages/CompletedTransferPage/CompletedTransferPage"));
const TransferCentralPage = lazy(() => import("../pages/TransferCentralPage/TransferCentralPage"));
const OutgoingTransfersPage = lazy(() =>
  import("../pages/OutgoingTransfersPage/OutgoingTransfersPage")
);
const OutgoingTransferPage = lazy(() =>
  import("../pages/OutgoingTransferPage/OutgoingTransferPage")
);
const IncomingTransfersPage = lazy(() =>
  import("../pages/IncomingTransfersPage/IncomingTransfersPage")
);
const IncomingTransferPage = lazy(() =>
  import("../pages/IncomingTransferPage/IncomingTransferPage")
);
const StoreTransferDispatchesPage = lazy(() =>
  import("../pages/StoreTransferDispatchesPage/StoreTransferDispatchesPage")
);
const PurchaseDispatchesPage = lazy(() =>
  import("../pages/PurchaseDispatchesPage/PurchaseDispatchesPage")
);
const PurchaseDispatchPage = lazy(() =>
  import("../pages/PurchaseDispatchPage/PurchaseDispatchPage")
);

const NewCashCountReportPage = lazy(() =>
  import("../pages/NewCashCountReportPage/NewCashCountReportPage")
);

const CashCountReportPage = lazy(() => import("../pages/CashCountReportPage/CashCountReportPage"));
const CashCountReportsPage = lazy(() =>
  import("../pages/CashCountReportsPage/CashCountReportsPage")
);

const ReturnedEmptyCarboysPage = lazy(() =>
  import("../pages/ReturnedEmptyCarboysPage/ReturnedEmptyCarboysPage")
);

const CashCountReportStoreConfigsPage = lazy(() =>
  import("../pages/CashCountReportStoreConfigsPage/CashCountReportStoreConfigsPage")
);

const ProductProductionQuantityReportPage = lazy(() =>
  import("../pages/ProductProductionQuantityReportPage/ProductProductionQuantityReportPage")
);

const authorizedRoutes: Array<{
  path: string;
  permissions: Array<string>;
  component: LazyExoticComponent<any>;
}> = [
  {
    path: "/expiration-date-report",
    permissions: ["products:expiration-date-read"],
    component: ExpirationDateReportPage
  },
  {
    path: "/stock-transfer",
    permissions: ["products:stock-transfer-write"],
    component: ProductStockTransferPage
  },
  {
    path: "/products/:productId",
    permissions: ["products:list-read"],
    component: ProductPage
  },
  {
    path: "/products",
    permissions: ["products:list-read"],
    component: ProductsPage
  },

  {
    path: "/inventories/:inventoryId/preview",
    permissions: [
      "products:inventories-read",
      "products:inventories-write",
      "products:inventory-cancel-write",
      "products:inventory-complete-write",
      "products:inventory-approve-write"
    ],
    component: InventoryPreviewPage
  },
  {
    path: "/inventories/:inventoryId/collections/:collectionId",
    permissions: [
      "products:inventories-read",
      "products:inventories-write",
      "products:inventory-cancel-write",
      "products:inventory-complete-write",
      "products:inventory-approve-write"
    ],
    component: InventoryCollectionPage
  },
  {
    path: "/inventories/:inventoryId",
    permissions: [
      "products:inventories-read",
      "products:inventories-write",
      "products:inventory-cancel-write",
      "products:inventory-complete-write",
      "products:inventory-approve-write"
    ],
    component: InventoryPage
  },
  {
    path: "/inventories",
    permissions: [
      "products:inventories-read",
      "products:inventories-write",
      "products:inventory-cancel-write",
      "products:inventory-complete-write",
      "products:inventory-approve-write"
    ],
    component: InventoriesPage
  },

  {
    path: "/expiration-check",
    permissions: ["products:expiration-check-read", "products:expiration-check-write"],
    component: ExpirationChecksPage
  },
  {
    path: "/expiration-check/:expirationCheckId",
    permissions: ["products:expiration-check-read", "products:expiration-check-write"],
    component: ExpirationCheckPage
  },

  {
    path: "/active-orders/:orderId",
    permissions: ["orders:active-read", "orders:active-write"],
    component: ActiveOrderPage
  },
  {
    path: "/active-orders",
    permissions: ["orders:active-read", "orders:active-write"],
    component: ActiveOrdersPage
  },

  {
    path: "/returned-orders/:orderId",
    permissions: ["orders:return-read", "orders:return-write"],
    component: ReturnedOrderPage
  },
  {
    path: "/returned-orders",
    permissions: ["orders:return-read", "orders:return-write"],
    component: ReturnedOrdersPage
  },
  {
    path: "/returned-empty-carboys",
    permissions: ["orders:active-read", "orders:active-write"],
    component: ReturnedEmptyCarboysPage
  },

  {
    path: "/users",
    permissions: ["users:actions-read", "users:actions-write"],
    component: UsersPage
  },
  {
    path: "/users/add",
    permissions: ["users:actions-write"],
    component: NewUserPage
  },
  {
    path: "/users/:userName",
    permissions: ["users:actions-read", "users:actions-write"],
    component: EditUserPage
  },

  {
    path: "/pos-users",
    permissions: ["pos-users:actions-read", "pos-users:actions-write"],
    component: PosUsersPage
  },
  {
    path: "/pos-users/:userName",
    permissions: ["pos-users:actions-read", "pos-users:actions-write"],
    component: EditPosUserPage
  },
  {
    path: "/requests/add",
    permissions: ["requests:new-write"],
    component: RequestPage
  },
  {
    path: "/requests/material",
    permissions: ["requests:material-write"],
    component: RequestPage
  },
  {
    path: "/requests",
    permissions: ["requests:store-read", "requests:store-write"],
    component: RequestsPage
  },
  {
    path: "/requests/purchase-return",
    permissions: ["requests:purchase-return-read", "requests:purchase-return-write"],
    component: PurchaseReturnRequestsPage
  },
  {
    path: "/requests/completed",
    permissions: ["requests:completed-read", "requests:completed-write"],
    component: CompletedRequestsPage
  },
  {
    path: "/requests/completed-blockage",
    permissions: ["requests:completed-read", "requests:completed-write"],
    component: CompletedBlockageRequestsPage
  },
  {
    path: "/requests/reconciliation",
    permissions: ["requests:reconciliation-read", "requests:reconciliation-write"],
    component: ReconciliationRequestsPage
  },
  {
    path: "/requests/slips",
    permissions: ["requests:slips-read", "requests:slips-write"],
    component: RequestSlipsPage
  },
  {
    path: "/requests/dispatches",
    permissions: ["requests:dispatches-read", "requests:dispatches-write"],
    component: RequestDispatchesPage
  },
  {
    path: "/requests/reasons",
    permissions: ["requests:reasons-read", "requests:reasons-write"],
    component: RequestReasonsPage
  },
  {
    path: "/requests/rules",
    permissions: ["requests:rules-read", "requests:rules-write"],
    component: RequestApprovalRulesPage
  },

  {
    path: "/payments-approve",
    permissions: ["payments:approve-read", "payments:approve-write", "payments:correction-write"],
    component: ApprovePaymentsPage
  },

  {
    path: "/payments-report",
    permissions: ["payments:report-read"],
    component: PaymentReportsPage
  },

  {
    path: "/verifone-report",
    permissions: ["accounting-reports:verifone-transactions"],
    component: VerifoneReportsPage
  },
  {
    path: "/purchases/:orderId",
    permissions: ["purchases:new-read", "purchases:new-write"],
    component: PurchasesNewPage
  },
  {
    path: "/purchases",
    permissions: ["purchases:new-read", "purchases:new-write"],
    component: PurchasesPage
  },
  {
    path: "/purchase-dispatches/:collectionId",
    permissions: ["purchases:dispatches-read", "purchases:dispatches-write"],
    component: PurchaseDispatchPage
  },
  {
    path: "/purchase-dispatches",
    permissions: ["purchases:dispatches-read", "purchases:dispatches-write"],
    component: PurchaseDispatchesPage
  },

  {
    path: "/transfers/new-central",
    permissions: [
      "transfers:requests-read",
      "transfers:requests-write",
      "transfers:new-central-write"
    ],
    component: TransferCentralPage
  },
  {
    path: "/completed-transfers/:transferId",
    permissions: ["transfers:requests-read", "transfers:requests-write"],
    component: CompletedTransferPage
  },
  {
    path: "/completed-transfers",
    permissions: ["transfers:requests-read", "transfers:requests-write"],
    component: CompletedTransfersPage
  },
  {
    path: "/transfers/:transferId",
    permissions: ["transfers:requests-read", "transfers:requests-write"],
    component: TransferPage
  },
  {
    path: "/transfers/new",
    permissions: ["transfers:requests-write"],
    component: TransferPage
  },
  {
    path: "/transfers",
    permissions: ["transfers:requests-read", "transfers:requests-write"],
    component: TransfersPage
  },
  {
    path: "/outgoing-transfers/:transferId",
    permissions: ["transfers:outgoing-read", "transfers:outgoing-write"],
    component: OutgoingTransferPage
  },
  {
    path: "/outgoing-transfers",
    permissions: ["transfers:outgoing-read", "transfers:outgoing-write"],
    component: OutgoingTransfersPage
  },
  {
    path: "/incoming-transfers/:collectionId",
    permissions: ["transfers:incoming-read", "transfers:incoming-write"],
    component: IncomingTransferPage
  },
  {
    path: "/incoming-transfers",
    permissions: ["transfers:incoming-read", "transfers:incoming-write"],
    component: IncomingTransfersPage
  },
  {
    path: "/my-store-transfers",
    permissions: ["transfers:my-stores-write"],
    component: MyStoreTransfersPage
  },
  {
    path: "/transfer-dispatches",
    permissions: ["transfers:dispatches-read"],
    component: StoreTransferDispatchesPage
  },
  {
    path: "/cash-count-reports/new",
    permissions: [
      "accounting-reports:cash-count-report-read",
      "accounting-reports:cash-count-report-create-edit-write",
      "accounting-reports:cash-count-report-approve-decline-write"
    ],
    component: NewCashCountReportPage
  },
  {
    path: "/cash-count-reports",
    permissions: [
      "accounting-reports:cash-count-report-read",
      "accounting-reports:cash-count-report-create-edit-write",
      "accounting-reports:cash-count-report-approve-decline-write"
    ],
    component: CashCountReportsPage
  },
  {
    path: "/cash-count-reports/:reportId",
    permissions: [
      "accounting-reports:cash-count-report-read",
      "accounting-reports:cash-count-report-create-edit-write",
      "accounting-reports:cash-count-report-approve-decline-write"
    ],
    component: CashCountReportPage
  },
  {
    path: "/cash-count-report-store-advance",
    permissions: [
      "accounting-reports:cash-count-report-store-advance-read",
      "accounting-reports:cash-count-report-store-advance-write"
    ],
    component: CashCountReportStoreConfigsPage
  },
  {
    path: "/product-production-quantity-report",
    permissions: ["products:production-quantity-report-read"],
    component: ProductProductionQuantityReportPage
  },
];
function Routes() {
  const adminService = useAdminService();
  const authGlobal = useAuthGlobal();
  const [token, cancelToken] = useCancelToken();

  const [shouldRender, setShouldRender] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<Array<string> | null>(null);

  useEffect(() => {
    if (authGlobal.isAuthenticated === null) return;

    if (!authGlobal.isAuthenticated) {
      setShouldRender(true);
      return;
    }

    async function getUserPermissions() {
      const userPermissions = await adminService.getPermissions(token);

      setPermissions(userPermissions);
    }

    getUserPermissions().finally(() => setShouldRender(true));

    return function cleanup() {
      cancelToken();
    };
  }, [authGlobal.isAuthenticated]);

  return (
    <>
      {shouldRender && (
        <>
          {authGlobal.isAuthenticated ? (
            <Layout>
              <Suspense fallback={<div />}>
                <Switch>
                  {permissions !== null &&
                    authorizedRoutes.map((route, index) => {
                      if (checkPermission(permissions, route.permissions))
                        return (
                          <PrivateRoute
                            key={index}
                            exact
                            path={route.path}
                            component={route.component}
                          />
                        );
                    })}
                  <PrivateRoute exact path="/my-account" component={MyAccountPage} />
                  <PrivateRoute exact path="/" component={DashboardPage} />
                  <PrivateRoute exact={false} path="*" component={NotFoundPage} />
                </Switch>
              </Suspense>
            </Layout>
          ) : (
            <Suspense fallback={<div />}>
              <Switch>
                <PublicRoute exact={true} path="/login" component={LoginPage} />
                <Redirect to="/login" />
              </Switch>
            </Suspense>
          )}
        </>
      )}
    </>
  );
}

export default Routes;
