import React, { createContext, useContext } from "react";

interface IEnvironmentContext {
  environment: string;
  authApiUrl: string;
  adminApiUrl: string;
}

const EnvironmentContext = createContext<IEnvironmentContext>({} as IEnvironmentContext);

export function useEnvironmentGlobal(): IEnvironmentContext {
  return useContext(EnvironmentContext);
}

interface IProps {
  children: React.ReactNode;
}

export function EnvironmentGlobal(props: IProps) {
  const value: IEnvironmentContext = {
    environment: process.env.REACT_APP_ENVIRONMENT ?? "",
    authApiUrl: process.env.REACT_APP_API_URL_AUTH ?? "",
    adminApiUrl: process.env.REACT_APP_API_URL_ADMIN ?? ""
  };

  return <EnvironmentContext.Provider value={value}>{props.children}</EnvironmentContext.Provider>;
}
