import React, { createContext, useContext, useEffect, useState } from "react";
import { CssBaseline, jssPreset, Theme } from "@material-ui/core";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import StylesProvider from "@material-ui/styles/StylesProvider";
import useThemeHelper from "../../utility/helpers/ThemeHelper";
import { useLocaleGlobal } from "../LocaleGlobal/LocaleGlobal";
import { create } from "jss";
import rtl from "jss-rtl";
const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
  insertionPoint: document.getElementById("jss-insertion-point") || "jss-insertion-point"
});

interface IThemeGlobalContext {
  defaultThemeType: string;
  currentThemeType: string;
  updateCurrentThemeType: (theme: string) => void;
}

const ThemeGlobalContext = createContext<IThemeGlobalContext>({} as IThemeGlobalContext);

export function useThemeGlobal(): IThemeGlobalContext {
  return useContext(ThemeGlobalContext);
}

interface IProps {
  children: React.ReactNode;
}

export function ThemeGlobal(props: IProps) {
  const THEME_STORAGE_KEY = "theme";
  const DEFAULT_THEME_TYPE = "light";
  const [currentThemeType, setCurrentThemeType] = useState<string>(DEFAULT_THEME_TYPE);
  const [selectedTheme, setSelectedTheme] = useState<Theme>();
  const themeHelper = useThemeHelper();
  const localeGlobal = useLocaleGlobal();
  const defaultTheme = themeHelper.getTheme(DEFAULT_THEME_TYPE, localeGlobal.currentDir);

  useEffect(() => {
    const themeFromStorage = localStorage.getItem(THEME_STORAGE_KEY);

    if (themeFromStorage && themeHelper.availableThemeTypes.includes(themeFromStorage)) {
      setCurrentThemeType(themeFromStorage);
    } else {
      localStorage.setItem(THEME_STORAGE_KEY, DEFAULT_THEME_TYPE);
      setCurrentThemeType(DEFAULT_THEME_TYPE);
    }
  }, []);

  useEffect(() => {
    setSelectedTheme(themeHelper.getTheme(currentThemeType, localeGlobal.currentDir));
  }, [currentThemeType, localeGlobal.currentDir]);

  function updateCurrentThemeType(theme: string): void {
    if (themeHelper.availableThemeTypes.includes(theme)) {
      localStorage.setItem(THEME_STORAGE_KEY, theme);
      setCurrentThemeType(theme);
    } else {
      localStorage.setItem(THEME_STORAGE_KEY, DEFAULT_THEME_TYPE);
      setCurrentThemeType(DEFAULT_THEME_TYPE);
    }
  }

  const value: IThemeGlobalContext = {
    defaultThemeType: DEFAULT_THEME_TYPE,
    currentThemeType: currentThemeType,
    updateCurrentThemeType: updateCurrentThemeType
  };

  return (
    <ThemeGlobalContext.Provider value={value}>
      <StylesProvider jss={jss}>
        <ThemeProvider theme={selectedTheme || defaultTheme}>
          <CssBaseline />
          {props.children}
        </ThemeProvider>
      </StylesProvider>
    </ThemeGlobalContext.Provider>
  );
}
