import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

interface IProps {
  width: string | null;
  height: string | null;
}

function SkeletonText(props: IProps) {
  return (
    <Skeleton
      variant="text"
      width={props.width !== null ? props.width : "100%"}
      height={props.height !== null ? props.height : 35}
      animation="wave"
    />
  );
}

export default SkeletonText;
