import React, { createContext, useContext } from "react";
import { useAxiosService } from "../AxiosService/AxiosService";
import axios, { AxiosRequestConfig, CancelToken } from "axios";
import { IGetAccessTokenModel, ILoginModel } from "./models";
import { IAccessTokenResponse } from "./responses";
import { useEnvironmentGlobal } from "../../globals/EnvironmentGlobal/EnvironmentGlobal";
import queryString from "query-string";

interface IAuthServiceContext {
  login: (token: CancelToken, model: ILoginModel) => Promise<IAccessTokenResponse>;
  getAccessToken: (
    token: CancelToken,
    model: IGetAccessTokenModel
  ) => Promise<IAccessTokenResponse>;
}

const AuthServiceContext = createContext<IAuthServiceContext>({} as IAuthServiceContext);

interface IProps {
  children: React.ReactElement;
}

export function AuthService(props: IProps) {
  const environmentGlobal = useEnvironmentGlobal();

  const axiosService = useAxiosService(
    axios.create({
      baseURL: environmentGlobal.authApiUrl,
      timeout: 20000,
      responseType: "json",
      headers: { "Cache-Control": "no-cache,no-store" },
      paramsSerializer: (params) => {
        return queryString.stringify(params, { arrayFormat: "none" });
      }
    })
  );

  function login(token: CancelToken, model: ILoginModel): Promise<IAccessTokenResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "/users/login",
      data: {
        userName: model.UserName,
        password: model.Password,
        companyId: model.CompanyId
      },
      cancelToken: token
    };

    return axiosService.send<IAccessTokenResponse>(config);
  }

  function getAccessToken(
    token: CancelToken,
    model: IGetAccessTokenModel
  ): Promise<IAccessTokenResponse> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "/users/access-token",
      data: {
        refreshToken: model.RefreshToken,
        companyId: 1
      },
      cancelToken: token
    };

    return axiosService.send<IAccessTokenResponse>(config);
  }

  const value: IAuthServiceContext = {
    login: login,
    getAccessToken: getAccessToken
  };

  return <AuthServiceContext.Provider value={value}>{props.children}</AuthServiceContext.Provider>;
}

export function useAuthService(): IAuthServiceContext {
  return useContext(AuthServiceContext);
}
