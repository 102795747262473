
function checkPermission(userPermissions:string[],itemPermissions:string[] | null): boolean {

    let result=false;
    
     userPermissions.forEach((permission,index)=>{
        if(permission.includes("admin:*") || itemPermissions?.includes(permission)){
            result=true;
        }
    });

    return result;
  
}

export default checkPermission;
