import React from "react";
import { Grid, List } from "@material-ui/core";
import SidebarMenuItem, { IMenuItem } from "./SidebarMenuItem";
import SkeletonText from "../../Skeletons/SkeletonText";

interface IProps {
  onSidebarClose: () => void;
  permissions: Array<string> | null;
  openMenuItemId: string;
  onMenuItemClick: (id: string) => void;
  menuItems: Array<IMenuItem>;
}

function SidebarMenu(props: IProps) {
  return (
    <List>
      {props.menuItems.map((mi: IMenuItem, index: number) =>
        props.permissions !== null ? (
          <SidebarMenuItem
            key={mi.id}
            item={mi}
            onSidebarClose={props.onSidebarClose}
            permissions={props.permissions}
            isNestedListOpen={props.openMenuItemId === mi.id}
            onClick={props.onMenuItemClick}
          />
        ) : (
          <Grid container justify="center" key={index}>
            <Grid item>
              <SkeletonText width="14rem" height="3rem" />
            </Grid>
          </Grid>
        )
      )}
    </List>
  );
}

export default SidebarMenu;
